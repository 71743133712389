import { AnimatePresence } from "framer-motion";
import React from "react";
import { Helmet } from "react-helmet";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  Error,
  FadeIn,
  Flex,
  H2,
  Loading,
  Responsive,
  Section,
  Stack,
} from "../components";
import { useNightMarketPage, usePageIds } from "../features/content";
import { SectionSwitch } from "../features/marketplace";
import { Layout } from "../features/shared";
import { Activity, Assets, Collections, Market } from "../routes/night-market";
import { theme } from "../utils/theme";

const NightMarket = () => {
  const { data, loading, error } = usePageIds();

  if (error)
    return (
      <Layout>
        <Error />
      </Layout>
    );
  if (loading || !data)
    return (
      <Layout>
        <Loading />
      </Layout>
    );

  const nightMarketPageId = data.nightMarketPages[0].id;

  return (
    <Layout>
      <Helmet>
        <title>
          Kuro Shiba | Night Market - Trade Stakeable NFTs on Harmony!
        </title>
        <meta
          name="description"
          content="Buy, sell, and trade exclusive, rare, and adorable Kuro Shiba NFTs in the Night Market. All transactions occur right on the ultra-fast Harmony blockchain, with near-zero gas! 🧋"
        />
      </Helmet>
      <Content id={nightMarketPageId} />
    </Layout>
  );
};

export { NightMarket };

const Content: React.FC<{ id: string }> = ({ id }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const {
    data: nightMarketPageData,
    loading,
    error,
  } = useNightMarketPage({ variables: { id } });

  if (error) return <Error />;

  if (loading || !nightMarketPageData) return <Loading />;

  const nightMarketPage = nightMarketPageData.nightMarketPage;

  const Header = () => {
    return (
      <>
        <Responsive
          xs={
            <>
              {pathname.includes("night-market/market") && (
                <H2 gradient style={{ textAlign: "center" }}>
                  Night Market
                </H2>
              )}
              {pathname.includes("night-market/collections") && (
                <H2 gradient style={{ textAlign: "center" }}>
                  Collections
                </H2>
              )}
              {pathname.includes("night-market/assets") && (
                <H2 gradient style={{ textAlign: "center" }}>
                  My NFTs
                </H2>
              )}
              {pathname.includes("night-market/activity") && (
                <H2 gradient style={{ textAlign: "center" }}>
                  My Activity
                </H2>
              )}
            </>
          }
          md={
            <Stack gap={1} alignItems="center">
              <H2 as="h1" gradient style={{ textAlign: "center" }}>
                {nightMarketPage.heading}
              </H2>
              <Flex>
                <SectionSwitch
                  active={pathname.includes("night-market/market")}
                  onClick={() => navigate("/night-market/market")}
                  leftBorderRadius
                >
                  Marketplace
                </SectionSwitch>
                <SectionSwitch
                  active={pathname.includes("night-market/collections")}
                  onClick={() => navigate("/night-market/collections")}
                >
                  Collections
                </SectionSwitch>
                <SectionSwitch
                  active={pathname.includes("night-market/assets")}
                  onClick={() => navigate("/night-market/assets")}
                >
                  My NFTs
                </SectionSwitch>
                <SectionSwitch
                  active={pathname.includes("night-market/activity")}
                  onClick={() => navigate("/night-market/activity")}
                  rightBorderRadius
                >
                  My Activity
                </SectionSwitch>
              </Flex>
            </Stack>
          }
        />
      </>
    );
  };

  return (
    <AnimatePresence exitBeforeEnter>
      <Section>
        <FadeIn>
          <Wrapper alignItems="center" gap={2}>
            <Header />

            <Routes>
              <Route path="/market" element={<Market />} />
              <Route path="/collections" element={<Collections />} />
              <Route path="/assets" element={<Assets />} />
              <Route path="/activity" element={<Activity />} />
            </Routes>
          </Wrapper>
        </FadeIn>
      </Section>
    </AnimatePresence>
  );
};

const Wrapper = styled(Stack)`
  padding-bottom: 8rem;

  @media (max-width: ${theme.breakpoints.sm}px) {
    padding-bottom: 2rem;
  }
`;
