export function abbreviateETHAddress(
  ethAddress: string,
  secondHalfIndex?: number
) {
  return (
    String(ethAddress).substring(0, 6) +
    "..." +
    String(ethAddress).substring(secondHalfIndex || 38)
  );
}
