import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { TransferPage } from "./types";

type TransferPageData = {
  transferPageV2: TransferPage;
};

type TransferPageVariables = {
  id: string;
};

export const useTransferPage = (
  options?: QueryHookOptions<TransferPageData, TransferPageVariables>
) => {
  return useQuery<TransferPageData, TransferPageVariables>(
    TRANSFER_PAGE,
    options
  );
};

export const TRANSFER_PAGE = gql`
  query transferPage($id: ID!) {
    transferPageV2(where: { id: $id }) {
      id
      heading
      nfts {
        name
        nftAddress
        stakeAddress
      }
    }
  }
`;
