export function unique(array: any[]) {
  return [...new Set(array)];
}

export function uniqueBy(array: any[], key: string) {
  var o: any = {};

  array.forEach((x, i) => {
    if (x[key] in o) array.splice(i, 1);
    o[x[key]] = null;
  });

  return array;
}
