export const theme = {
  maxPageWidth: 1300,
  transition: "all 0.3s ease",

  breakpoints: {
    tiny: 450 /* Phone */,
    xs: 900 /* Tablet */,
    sm: 1200 /* Laptop */,
    md: 1800 /* PC */,
  },
};
