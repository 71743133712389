import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { AlertProps, alertState, initialAlert } from "../shared";

const useAlert = () => {
  const [alert, setAlert] = useRecoilState(alertState);

  useEffect(() => {
    if (!alert) return;

    if (alert.isOpen) {
      let timer = setTimeout(
        () => setAlert({ ...alert, isOpen: false }),
        alert.timeout || 5000
      );

      return () => {
        clearTimeout(timer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert]);

  function open(
    props: Pick<AlertProps, "children" | "timeout" | "dismissable"> | string
  ) {
    if (typeof props === `string`) {
      setAlert({
        isOpen: true,
        children: <div>{props}</div>,
        timeout: 5000,
        dismissable: true,
      });
      return;
    }

    setAlert({ isOpen: true, ...props });
  }

  function close() {
    setAlert(initialAlert);
  }

  return { alert, open, close };
};

export { useAlert };
