import React from "react";
import { Grid } from ".";

const Error: React.FC = ({ children }) => {
  return (
    <Grid justifyContent="center" alignItems="center">
      {children ?? <div>Something went wrong.</div>}
    </Grid>
  );
};

export { Error };
