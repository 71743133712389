import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useSetRecoilState } from "recoil";
import { Asset, ListNFTModal, LoadingImage, NFTCard, NFTTokenURI } from ".";
import NFTContract from "../../compiled-contracts/marketplace/NFT.json";
import { Button, Grid, Spinner, Vendor } from "../../components";
import { useContractQuery } from "../contracts";
import { modalState, useAlert } from "../shared";

interface AssetCardProps {
  asset: Asset;
  refetch: () => Promise<void>;
}

const AssetCard: React.FC<AssetCardProps> = ({ asset, refetch }) => {
  const alert = useAlert();
  const setModal = useSetRecoilState(modalState);

  const nftDataQuery = useContractQuery(
    NFTContract,
    asset.address,
    async (contract) => {
      const tokenID = Number(asset.tokenID);

      const url = await contract.methods.uri(tokenID).call();
      const res = await fetch(url);

      if (res.ok) {
        return res.json();
      }
    }
  );

  function openListNFTModal() {
    setModal({
      type: "LIST_NFT",
      children: (closeModal) => (
        <ListNFTModal
          {...{
            asset,
            closeModal,
            onCompleted: async () => {
              alert.open("Successfully Listed");
              await refetch();
              closeModal();
            },
          }}
        />
      ),
    });
  }

  if (!nftDataQuery.data) return null;

  const nft: NFTTokenURI = nftDataQuery.data;

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} layout>
      <NFTCard.Card
        name={<Vendor address={nft.vendor} />}
        image={
          <LoadingImage>
            <AnimatePresence>
              {nft ? (
                <motion.img
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.15 }}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={nft.preview}
                  alt={nft.name}
                />
              ) : (
                <Spinner />
              )}
            </AnimatePresence>
          </LoadingImage>
        }
      >
        <Grid>
          <h2
            style={{
              textAlign: "center",
              marginTop: "-0.5rem",
              fontSize: "16pt",
            }}
          >
            {nft?.name || <Spinner />} #{asset.tokenID}
          </h2>

          <Button dark onClick={openListNFTModal}>
            List
          </Button>
        </Grid>
      </NFTCard.Card>
    </motion.div>
  );
};

export { AssetCard };
