import styled from "styled-components";
import { FadeIn } from "../../components";
import { theme } from "../../utils/theme";
import { NFT, NFTv2 } from "../content";

interface NFTsSectionType {
  nfts: Array<NFT | NFTv2>;

  content: ({ data }: { data: NFT | NFTv2 }) => React.ReactNode;
}

const NFTsSection: React.FC<NFTsSectionType> = ({ nfts, content }) => {
  return (
    <CardGrid>
      {nfts.map((data) => {
        return <FadeIn key={data.name}>{content({ data })}</FadeIn>;
      })}
    </CardGrid>
  );
};

export { NFTsSection };

const CardGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  gap: 2rem;

  & > * {
    width: calc(${theme.maxPageWidth / 3}px - 4rem);

    @media (max-width: ${theme.breakpoints.xs}px) {
      width: 100%;
    }
  }
`;
