import ReactMarkdown from "react-markdown";
import styled from "styled-components";

const Markdown: React.FC<{ children: string }> = ({ children, ...props }) => {
  return (
    <StyledMarkdown
      {...{ props }}
      components={{
        a: ({ href, children }) => {
          return (
            <a {...{ href }} target="_blank" rel="noreferrer">
              {children[0]}
            </a>
          );
        },
      }}
    >
      {children}
    </StyledMarkdown>
  );
};

const StyledMarkdown = styled(ReactMarkdown)`
  & a {
    text-decoration: underline;
    color: #caa3ff;
    cursor: cell;
  }
`;

export { Markdown };
