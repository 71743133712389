import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Responsive, Stack } from "../../components";

const Nav = () => {
  const { pathname } = useLocation();

  return (
    <>
      <Navlink to="/mint" active={pathname.includes("mint")}>
        MINT! <Star>✧</Star>
      </Navlink>
      <Navlink to="/museum" active={pathname.includes("museum")}>
        Art Museum
      </Navlink>
      <Navlink to="/exhibitions" active={pathname.includes("exhibitions")}>
        Exhibitions
      </Navlink>

      <Responsive
        xs={
          <Stack gap={0.25}>
            <Navlink
              to="/night-market/market"
              active={pathname.includes("night-market")}
            >
              Night Market <Star>✧</Star>
            </Navlink>
            <Navlink
              indented
              to="/night-market/collections"
              active={pathname.includes("night-market/collections")}
            >
              Collections
            </Navlink>
            <Navlink
              indented
              to="/night-market/assets"
              active={pathname.includes("night-market/assets")}
            >
              My NFTs
            </Navlink>
            <Navlink
              indented
              to="/night-market/activity"
              active={pathname.includes("night-market/activity")}
            >
              My Activity
            </Navlink>
          </Stack>
        }
        md={
          <Navlink
            to="/night-market/market"
            active={pathname.includes("night-market")}
          >
            Night Market <Star>✧</Star>
          </Navlink>
        }
      />

      <Navlink to="/ponds" active={pathname.includes("ponds")}>
        Fish Ponds
      </Navlink>
      <Navlink to="/airmail" active={pathname.includes("airmail")}>
        Airmail <Star>✧</Star>
      </Navlink>
    </>
  );
};

export { Nav };

const Star = styled.span`
  line-height: 1;
  display: inline-block;
  animation: rotate 3s ease-in-out forwards infinite;
`;

const Navlink = styled(Link)<{ active?: boolean; indented?: boolean }>`
  ${({ active }) => active && { color: "#a675ff" }};
  ${({ indented }) => indented && { marginLeft: "1rem" }}

  &:hover {
    color: #a675ff;

    & ${Star} {
      animation: rotate 1s linear forwards infinite;
    }
  }
`;

// const Superscript = styled.sup`
//   font-size: 6pt;
//   font-weight: bold;
// `;
