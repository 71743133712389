import { AnimatePresence } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { useAlert } from ".";
import { Flex } from "../../components";
import { Close } from "../../components/icons";

const Alert: React.FC = () => {
  const { alert, close } = useAlert();

  return (
    <AnimatePresence>
      {alert.isOpen && (
        <Container>
          <Wrapper
            gap={2}
            initial={{ opacity: 0, y: "-1rem" }}
            animate={{ opacity: 1, y: "0rem" }}
            exit={{ opacity: 0, y: "-1rem" }}
            transition={{ duration: 0.15, ease: "easeInOut" }}
          >
            <div>{alert.children}</div>
            {alert.dismissable && (
              <div
                onClick={close}
                style={{ width: 20, height: 20, cursor: "pointer" }}
              >
                <Close />
              </div>
            )}
          </Wrapper>
        </Container>
      )}
    </AnimatePresence>
  );
};

export { Alert };

const Container = styled(Flex)`
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0;

  justify-content: center;
`;

const Wrapper = styled(Flex)`
  align-items: center;

  margin: 1rem;
  padding: 0.25rem 0.25rem 0.25rem 0.75rem;

  border-radius: 8rem;
  background: black;
  color: white;
`;
