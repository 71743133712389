import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import ArrayHelperContract from "../compiled-contracts/exhibitions/ArrayHelper.json";
import NFTContract from "../compiled-contracts/exhibitions/NFT.json";
import NFTStakeContract from "../compiled-contracts/exhibitions/NFTStake.json";
import KuroContract from "../compiled-contracts/utils/Kuro.json";
import {
  Button,
  Error,
  FadeIn,
  H2,
  Loading,
  Markdown,
  OwnedTokens,
  Section,
} from "../components";
import {
  ExhibitionsPage,
  NFT,
  useExhibitionsPage,
  usePageIds,
} from "../features/content";
import { useContractMutation, useContractQuery } from "../features/contracts";
import { UnstakeModal } from "../features/exhibitions";
import { StakeModal } from "../features/exhibitions/StakeModal";
import { NFTCard, NFTsSection, SectionSwitch } from "../features/marketplace";
import { Layout, modalState } from "../features/shared";
import {
  ethAddressState,
  isHarmonyNetworkConnected,
  walletAuthorisedState,
} from "../features/wallet/WalletState";
import { toSmallN, unique } from "../utils";
import useInterval from "../utils/useInterval";

const Exhibitions = () => {
  const { data, loading, error } = usePageIds();

  if (error)
    return (
      <Layout>
        <Error />
      </Layout>
    );
  if (loading || !data)
    return (
      <Layout>
        <Loading />
      </Layout>
    );

  const exhibitionsPageId = data.exhibitionsPages[0].id;

  return (
    <Layout>
      <Helmet>
        <title>
          Kuro Shiba | Exhibitions - Stake Curated NFTs to Earn Tokens!
        </title>
        <meta
          name="description"
          content="Featuring curated NFT collections with staking rewards in ONE or HRC-20 tokens."
        />
      </Helmet>
      <Content id={exhibitionsPageId} />
    </Layout>
  );
};

export { Exhibitions };

const Content: React.FC<{ id: string }> = ({ id }) => {
  const { data, loading, error } = useExhibitionsPage({ variables: { id } });

  const [currentSectionTitle, setCurrentSectionTitle] = useState<
    "ACTIVE" | "INACTIVE"
  >("ACTIVE");

  if (error) return <Error />;
  if (loading || !data) return <Loading />;

  const { exhibitionsPage } = data;

  return (
    <AnimatePresence exitBeforeEnter>
      <Section>
        <FadeIn>
          <Wrapper>
            <H2 as="h1" gradient style={{ textAlign: "center" }}>
              {exhibitionsPage.heading}
            </H2>

            {/* <p>{exhibitionsPage.description}</p> */}

            <Switch>
              <SectionSwitch
                active={currentSectionTitle === "ACTIVE"}
                onClick={() => setCurrentSectionTitle("ACTIVE")}
                leftBorderRadius
              >
                Active
              </SectionSwitch>
              <SectionSwitch
                active={currentSectionTitle === "INACTIVE"}
                onClick={() => setCurrentSectionTitle("INACTIVE")}
                rightBorderRadius
              >
                Inactive
              </SectionSwitch>
            </Switch>

            {currentSectionTitle === "ACTIVE" && (
              <NFTsSection
                nfts={exhibitionsPage.activeNfts}
                content={({ data }) =>
                  `nftStakeAddress` in data && (
                    <NFTContent
                      {...{ data }}
                      isActive
                      {...{ exhibitionsPage }}
                    />
                  )
                }
              />
            )}
            {currentSectionTitle === "INACTIVE" && (
              <NFTsSection
                nfts={exhibitionsPage.inactiveNfts}
                content={({ data }) =>
                  `nftStakeAddress` in data && (
                    <NFTContent
                      {...{ data }}
                      isActive={false}
                      {...{ exhibitionsPage }}
                    />
                  )
                }
              />
            )}
          </Wrapper>
        </FadeIn>
      </Section>
    </AnimatePresence>
  );
};

const { Card, Actions, Buttons, ContentRow, Overlay } = NFTCard;

const NFTContent: React.FC<{
  data: NFT;
  isActive?: boolean;
  exhibitionsPage: ExhibitionsPage;
}> = ({ data, isActive, exhibitionsPage }) => {
  const ethAddress = useRecoilValue(ethAddressState);
  const walletAuthorised = useRecoilValue(walletAuthorisedState);
  const harmonyNetworkConnected = useRecoilValue(isHarmonyNetworkConnected);
  const setModal = useSetRecoilState(modalState);

  /* Admission Hooks */

  const admissionFeeQuery = useContractQuery(
    NFTStakeContract,
    data.nftStakeAddress,
    async (contract) => {
      return await contract.methods.fee().call();
    }
  );

  const userAllowanceQuery = useContractQuery(
    KuroContract,
    data.kuroAddress,
    async (contract, ethAddress) => {
      return await contract.methods
        .allowance(ethAddress, data.nftStakeAddress)
        .call();
    }
  );

  const isAdmissionFeePaidQuery = useContractQuery(
    NFTStakeContract,
    data.nftStakeAddress,
    async (contract, ethAddress) => {
      return await contract.methods.paidFee(ethAddress).call();
    }
  );

  const approveAdmissionFeeMutation = useContractMutation(
    KuroContract,
    data.kuroAddress,
    async (contract, ethAddress) => {
      const admissionFee = admissionFeeQuery.data;

      return await contract.methods
        .approve(data.nftStakeAddress, admissionFee)
        .send({ from: ethAddress });
    },
    {
      onCompleted: async () => {
        await userAllowanceQuery.fetch();
      },
    }
  );

  const payAdmissionFeeMutation = useContractMutation(
    NFTStakeContract,
    data.nftStakeAddress,
    async (contract, ethAddress) => {
      return await contract.methods.payFee().send({ from: ethAddress });
    },
    {
      onCompleted: async () => {
        await isAdmissionFeePaidQuery.fetch();
        await ownedTokensQuery.fetch();
        await NFTsUserStakedQuery.fetch();
        await claimedRewardsQuery.fetch();
      },
    }
  );

  const isCollectionApprovedQuery = useContractQuery(
    NFTContract,
    data.nftAddress,
    async (contract) => {
      return await contract.methods
        .isApprovedForAll(ethAddress, data.nftStakeAddress)
        .call();
    }
  );

  const approveCollectionMutation = useContractMutation(
    NFTContract,
    data.nftAddress,
    async (contract, ethAddress) => {
      return await contract.methods
        .setApprovalForAll(data.nftStakeAddress, true)
        .send({ from: ethAddress });
    },
    {
      onCompleted: async () => {
        await isCollectionApprovedQuery.fetch();
        await ownedTokensQuery.fetch();
        await NFTsUserStakedQuery.fetch();
        await claimedRewardsQuery.fetch();
      },
    }
  );

  /* Staking Hooks */

  const ownedTokensQuery = useContractQuery(
    ArrayHelperContract,
    data.arrayHelperAddress as string,
    async (contract, ethAddress) => {
      const res = await contract.methods.getIdArray(ethAddress).call();

      return res.map((item: BigInt) => item.toString());
    }
  );

  const NFTsUserStakedQuery = useContractQuery(
    NFTStakeContract,
    data.nftStakeAddress,
    async (contract, ethAddress) => {
      return await contract.methods.getAllNFTsUserStaked(ethAddress).call();
    }
  );

  const pendingRewardsQuery = useContractQuery(
    NFTStakeContract,
    data.nftStakeAddress,
    async (contract, ethAddress) => {
      return await contract.methods.getPendingRewards(ethAddress).call();
    }
  );

  const withdrawRewardsMutation = useContractMutation(
    NFTStakeContract,
    data.nftStakeAddress,
    async (contract, ethAddress) => {
      return await contract.methods
        .withdrawRewardsNoArray()
        .send({ from: ethAddress });
    },
    {
      onCompleted: async () => {
        await ownedTokensQuery.fetch();
        await NFTsUserStakedQuery.fetch();
        await pendingRewardsQuery.fetch();
        await claimedRewardsQuery.fetch();
      },
    }
  );

  const stakeMultipleNFTsMutation = useContractMutation(
    NFTStakeContract,
    data.nftStakeAddress,
    async (contract, ethAddress, { tokenIDs }) => {
      return await contract.methods
        .stakeNFTs(tokenIDs)
        .send({ from: ethAddress });
    },
    {
      onCompleted: async () => {
        await ownedTokensQuery.fetch();
        await NFTsUserStakedQuery.fetch();
        await pendingRewardsQuery.fetch();
      },
    }
  );

  const unstakeMultipleNFTsMutation = useContractMutation(
    NFTStakeContract,
    data.nftStakeAddress,
    async (contract, ethAddress, { tokenIDs }) => {
      return await contract.methods
        .unstakeNFTs(tokenIDs)
        .send({ from: ethAddress });
    },
    {
      onCompleted: async () => {
        await ownedTokensQuery.fetch();
        await NFTsUserStakedQuery.fetch();
        await pendingRewardsQuery.fetch();
      },
    }
  );

  /* Metadata Hooks */

  const tokensPerBlockQuery = useContractQuery(
    NFTStakeContract,
    data.nftStakeAddress,
    async (contract) => {
      return await contract.methods.tokensPerBlock().call();
    }
  );

  const getStakeContractBalanceQuery = useContractQuery(
    NFTStakeContract,
    data.nftStakeAddress,
    async (contract) => {
      return await contract.methods.getStakeContractBalance().call();
    }
  );

  const totalNFTsStakedQuery = useContractQuery(
    NFTStakeContract,
    data.nftStakeAddress,
    async (contract) => {
      return await contract.methods.totalNFTsStaked().call();
    }
  );

  const claimedRewardsQuery = useContractQuery(
    NFTStakeContract,
    data.nftStakeAddress,
    async (contract, ethAddress) => {
      return await contract.methods.pastClaims(ethAddress).call();
    }
  );

  /* Effects */

  useInterval(async () => {
    await pendingRewardsQuery.fetch();
  }, 20000);

  /* Variables */

  const ownedTokens: number[] | undefined = ownedTokensQuery.data?.map(
    (tokenID: string) => {
      return Number(tokenID);
    }
  );

  const stakedTokens: number[] | undefined = NFTsUserStakedQuery.data?.map(
    (tokenID: string) => {
      return Number(tokenID);
    }
  );

  const ownedAndStakedTokens = unique(
    [ownedTokens, stakedTokens]
      .flat()
      .filter((tokenID) => typeof tokenID === `number`)
  ) as number[];

  const contractBalance: number | undefined =
    Number(getStakeContractBalanceQuery.data) || undefined;
  const totalNFTsStaked: number | undefined =
    Number(totalNFTsStakedQuery.data) || undefined;

  const admissionFee: number | undefined =
    toSmallN(Number(admissionFeeQuery.data), 9) || undefined;

  const tokensPerBlock: number | undefined =
    toSmallN(Number(tokensPerBlockQuery.data), data.tokenFloat) || undefined;
  const claimedRewards: number | undefined =
    toSmallN(Number(claimedRewardsQuery.data), data.tokenFloat) || undefined;
  const pendingRewards: number | undefined =
    toSmallN(Number(pendingRewardsQuery.data), data.tokenFloat) || undefined;

  const daysLeft: number | undefined =
    contractBalance &&
    totalNFTsStaked &&
    tokensPerBlock &&
    toSmallN(Number(contractBalance), data.tokenFloat) /
      (((totalNFTsStaked * tokensPerBlock) / 2.3) * 86400);

  const isAdmissionFeeApproved =
    Number(userAllowanceQuery.data) >= Number(admissionFee);

  /* Helper Functions */

  async function handleApproveAdmissionFee() {
    await approveAdmissionFeeMutation.mutate({});
  }

  async function handlePayAdmissionFee() {
    await payAdmissionFeeMutation.mutate({});
  }

  async function handleApproveCollection() {
    await approveCollectionMutation.mutate({});
  }

  async function handleWithdrawRewards() {
    await withdrawRewardsMutation.mutate({});
  }

  function openStakeModal() {
    if (!ownedTokens) return;

    if (ownedTokens.length === 1) {
      stakeMultipleNFTsMutation.mutate({
        tokenIDs: ownedTokens,
      });
      return;
    }

    setModal({
      type: "EXHIBITIONS_STAKE",
      children: (closeModal) => (
        <StakeModal
          {...{
            data,
            closeModal,
            onCompleted: async () => {
              await ownedTokensQuery.fetch();
              await NFTsUserStakedQuery.fetch();
              await pendingRewardsQuery.fetch();
              await claimedRewardsQuery.fetch();
            },
          }}
        />
      ),
    });
  }

  function openUnstakeModal() {
    if (!stakedTokens) return;

    if (stakedTokens.length === 1) {
      unstakeMultipleNFTsMutation.mutate({
        tokenIDs: stakedTokens,
      });
      return;
    }

    setModal({
      type: "EXHIBITIONS_UNSTAKE",
      children: (closeModal) => (
        <UnstakeModal
          {...{
            data,
            closeModal,
            onCompleted: async () => {
              await ownedTokensQuery.fetch();
              await NFTsUserStakedQuery.fetch();
              await pendingRewardsQuery.fetch();
              await claimedRewardsQuery.fetch();
            },
          }}
        />
      ),
    });
  }

  /* Component Renderers */

  const OverlayContent = () => {
    if (!isActive)
      return (
        <Overlay>
          <h3>Coming Soon</h3>
        </Overlay>
      );
    if (!harmonyNetworkConnected)
      return (
        <Overlay>
          <h3>Connect to Harmony</h3>
        </Overlay>
      );
    if (!walletAuthorised || !ethAddress)
      return (
        <Overlay>
          <h3>Connect Wallet</h3>
        </Overlay>
      );
    return null;
  };

  const MutationErrors = () => {
    if (
      approveAdmissionFeeMutation.error ||
      payAdmissionFeeMutation.error ||
      approveCollectionMutation.error ||
      withdrawRewardsMutation.error ||
      stakeMultipleNFTsMutation.error ||
      unstakeMultipleNFTsMutation.error
    ) {
      return (
        <ErrorWrapper>
          {approveAdmissionFeeMutation.error ||
            payAdmissionFeeMutation.error ||
            approveCollectionMutation.error ||
            withdrawRewardsMutation.error ||
            stakeMultipleNFTsMutation.error ||
            unstakeMultipleNFTsMutation.error}
        </ErrorWrapper>
      );
    } else return null;
  };

  const RenderActions = () => {
    if (!isAdmissionFeeApproved && !isAdmissionFeePaidQuery.data) {
      return (
        <Actions>
          <Button
            dark
            onClick={handleApproveAdmissionFee}
            loading={approveAdmissionFeeMutation.loading}
            disabled={approveAdmissionFeeMutation.loading}
            loadingText="Approving Fee..."
          >
            Approve Admission Fee
          </Button>
          {isActive && (
            <ContentRow>
              <div style={{ textAlign: "center", padding: "0.5rem 0" }}>
                {exhibitionsPage.approveAdmissionHelperText}
              </div>
            </ContentRow>
          )}
          <MutationErrors />
        </Actions>
      );
    }

    if (!isAdmissionFeePaidQuery.data) {
      return (
        <Actions>
          <Button
            dark
            onClick={handlePayAdmissionFee}
            loading={payAdmissionFeeMutation.loading}
            disabled={payAdmissionFeeMutation.loading}
            loadingText="Paying Fee..."
          >
            Pay Admission Fee
          </Button>
          <MutationErrors />
        </Actions>
      );
    }

    if (!isCollectionApprovedQuery.data) {
      return (
        <Actions>
          <Button
            dark
            onClick={handleApproveCollection}
            disabled={approveCollectionMutation.loading}
            loading={approveCollectionMutation.loading}
          >
            Approve Collection
          </Button>
          <MutationErrors />
        </Actions>
      );
    }

    return (
      <Actions>
        <Buttons>
          <Button
            onClick={openStakeModal}
            loading={stakeMultipleNFTsMutation.loading}
            disabled={
              !ownedTokens ||
              ownedTokens?.length === 0 ||
              stakeMultipleNFTsMutation.loading
            }
            loadingText="Staking"
          >
            Stake
          </Button>
          <Button
            onClick={openUnstakeModal}
            loading={unstakeMultipleNFTsMutation.loading}
            disabled={
              !stakedTokens ||
              stakedTokens?.length === 0 ||
              unstakeMultipleNFTsMutation.loading
            }
            loadingText="Unstaking"
          >
            Unstake
          </Button>
        </Buttons>
        <Button
          dark
          onClick={handleWithdrawRewards}
          loading={withdrawRewardsMutation.loading}
          disabled={
            !stakedTokens ||
            stakedTokens?.length === 0 ||
            withdrawRewardsMutation.loading
          }
        >
          Claim {pendingRewards?.toLocaleString() || 0} {data.ticker || `KURO`}
        </Button>
        <MutationErrors />
      </Actions>
    );
  };

  return (
    <Card
      header={
        isActive && (
          <OwnedTokens
            loading={ownedTokensQuery.loading || NFTsUserStakedQuery.loading}
            tokens={ownedAndStakedTokens}
          />
        )
      }
      {...{ data }}
    >
      <ActionsWrapper>
        <OverlayContent />

        <ContentRow>
          <div style={{ textAlign: "center", width: "100%" }}>
            <Markdown>{data.description}</Markdown>
          </div>
        </ContentRow>

        {isActive && (
          <div>
            <ContentRow>
              <span>Admission Fee</span>
              <span>{admissionFee?.toLocaleString()} KURO</span>
            </ContentRow>
            <ContentRow>
              <span>Rewards Per Block</span>
              <span>
                {tokensPerBlock?.toPrecision(2).toLocaleString()}{" "}
                {data.ticker || `KURO`}
              </span>
            </ContentRow>
            <ContentRow>
              <span>Rewards Per Day</span>
              <span>
                ~{data.rewardsPerDay} {data.ticker || `KURO`}
              </span>
            </ContentRow>
            {totalNFTsStaked && (
              <ContentRow>
                <span>Total NFTs Staked</span>
                <span>{totalNFTsStaked}</span>
              </ContentRow>
            )}
            {daysLeft && (
              <ContentRow>
                <span>Time Left</span>
                <span>~{daysLeft.toLocaleString()} days</span>
              </ContentRow>
            )}
            {stakedTokens && stakedTokens.length > 0 && (
              <ContentRow>
                <span>Staked Token IDs</span>
                <span>
                  {stakedTokens.map((tokenID) => `#${tokenID}`).join(", ")}
                </span>
              </ContentRow>
            )}
            <ContentRow>
              <span>Claimed Rewards</span>
              <span>
                {claimedRewards?.toLocaleString() || 0} {data.ticker || `KURO`}
              </span>
            </ContentRow>
          </div>
        )}

        <RenderActions />
      </ActionsWrapper>
    </Card>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  padding-bottom: 8rem;
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  position: relative;
`;

const ErrorWrapper = styled.div`
  text-align: center;
  padding: 1rem;
  background: #ffc7c8;
  border-radius: 5px;
  margin-top: 0.5rem;
`;

const Switch = styled.div`
  display: flex;
`;
