import { gql, QueryHookOptions, useQuery } from "@apollo/client";

type LoadingImagesData = {
  loadingImages: Array<{
    image: {
      url: string;
    };
  }>;
};

export const useLoadingImages = (
  options?: QueryHookOptions<LoadingImagesData>
) => {
  return useQuery<LoadingImagesData>(LOADING_IMAGES, options);
};

export const LOADING_IMAGES = gql`
  query loadingImages {
    loadingImages {
      image {
        url
      }
    }
  }
`;
