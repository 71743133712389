import { AnimatePresence } from "framer-motion";
import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Error, FadeIn, H2, H3, Loading, Section } from "../components";
import { useFishPondsPage, usePageIds } from "../features/content";
import { Layout } from "../features/shared";

const FishPonds = () => {
  const { data, loading, error } = usePageIds();

  if (error)
    return (
      <Layout>
        <Error />
      </Layout>
    );
  if (loading || !data)
    return (
      <Layout>
        <Loading />
      </Layout>
    );

  const fishPondsPageId = data.fishPondsPages[0].id;

  return (
    <Layout>
      <Helmet>
        <title>Kuro Shiba | Fish Ponds - Provide Liquidity to Earn NFTs!</title>
        <meta
          name="description"
          content="Add liquidity to the Fish Ponds so Kuro Shiba can catch fish for you! You can redeem your fish for stakeable, yield-generating NFTs. Collect each NFT and earn KURO! 🐟"
        />
      </Helmet>
      <Content id={fishPondsPageId} />
    </Layout>
  );
};

export { FishPonds };

const Content: React.FC<{ id: string }> = ({ id }) => {
  const { data, loading, error } = useFishPondsPage({ variables: { id } });

  if (error) return <Error />;
  if (loading || !data) return <Loading />;

  const { fishPondsPage } = data;

  return (
    <AnimatePresence exitBeforeEnter>
      <Section>
        <FadeIn>
          <Wrapper>
            <H2 as="h1" gradient style={{ textAlign: "center" }}>
              {fishPondsPage.heading}
            </H2>
            <StyledContent>
              <Info>
                <H3 as="h2">{fishPondsPage.title}</H3>
                <p>{fishPondsPage.description}</p>
              </Info>

              <StyledImage
                src={fishPondsPage.image.url}
                alt={fishPondsPage.title}
              />
            </StyledContent>
          </Wrapper>
        </FadeIn>
      </Section>
    </AnimatePresence>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

const StyledContent = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  justify-content: center;

  gap: 2rem;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 32rem;

  text-align: center;
`;

const StyledImage = styled.img`
  width: 28rem;
  max-width: 80%;
`;
