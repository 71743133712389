import React from "react";
import styled from "styled-components";
import { theme } from "../utils/theme";
import { ChevronDown } from "./icons";

interface SelectProps {
  size?: "small";
}

const Select = styled.select<SelectProps>`
  /* Reset */
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  position: relative;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize 'line-height'. Cannot be changed from 'normal' in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable 'input' types in iOS */
  -webkit-appearance: none;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:focus {
    outline: none;
  }

  font-size: 12pt;
  padding: 1rem;

  ${({ size }) => {
    if (size === "small") return { padding: "0.5rem 1rem" };
  }};

  @media (max-width: ${theme.breakpoints.xs}px) {
    font-size: 11pt;
  }

  @media (max-width: ${theme.breakpoints.tiny}px) {
    min-width: 0;
    padding: 0.25rem 0.5rem;
  }
`;

const SelectWrapper: React.FC<{
  style?: React.CSSProperties;
  dark?: boolean;
}> = ({ children, style, dark, ...props }) => {
  return (
    <StyledSelectWrapper
      style={{ position: "relative", display: "flex", ...style }}
      {...{ dark, props }}
    >
      {children}
      <Icon>
        <ChevronDown />
      </Icon>
    </StyledSelectWrapper>
  );
};

const Icon = styled.div`
  pointer-events: none;
  position: absolute;

  width: 1.5rem;
  right: 0;

  /* Offsets chevron height */
  transform: translateY(2px);

  margin: 0 1rem;
`;

const StyledSelectWrapper = styled.div<{ dark?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  border-radius: 2rem;
  border: 2px solid black;

  overflow: hidden;

  & ${Select}, & ${Icon} {
    ${({ dark }) => {
      if (dark) return { background: "black", color: "white" };
      return { color: "black", background: "white" };
    }}
  }

  & select {
    width: 100%;
  }

  &:hover ${Icon}, &:hover {
    &:hover {
      background-image: linear-gradient(
        45deg,
        #ffe6b2,
        #ffc7c8 25%,
        #debfff 50%,
        #c8deff 75%,
        #e1ffcd
      );

      color: black;
    }
  }

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
`;

export { Select, SelectWrapper };
