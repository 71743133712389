import styled from "styled-components";
import { Button } from "../../components";
import { theme } from "../../utils/theme";

interface SectionSwitchProps {
  active?: boolean;

  leftBorderRadius?: boolean;
  rightBorderRadius?: boolean;
}

const SectionSwitch = styled(Button)<SectionSwitchProps>`
  border-radius: 0;

  transition: ${theme.transition};

  ${({ leftBorderRadius }) => {
    if (leftBorderRadius) {
      return { borderTopLeftRadius: "2rem", borderBottomLeftRadius: "2rem" };
    }
  }}

  ${({ rightBorderRadius }) => {
    if (rightBorderRadius) {
      return { borderTopRightRadius: "2rem", borderBottomRightRadius: "2rem" };
    }
  }}

  text-transform: none;
  font-weight: bold;

  background: white;
  background-image: ${({ active }) =>
    active &&
    `linear-gradient(45deg, #d1afff, #ffbcef 25%, #ffddc6 50%, #ffefd5 75%, #d1ffd8)`};

  border: none;

  &:hover {
    background: ${({ active }) =>
      !active
        ? "white"
        : `linear-gradient(45deg, #d1afff, #ffbcef 25%, #ffddc6 50%, #ffefd5 75%, #d1ffd8)`};
  }
`;

export { SectionSwitch };
