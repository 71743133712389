import styled from "styled-components";
import { theme } from "../../utils/theme";
import { NFT } from "../content";

export interface NFTCardProps {
  data?: NFT;
  image?: React.ReactNode;
  name?: React.ReactNode;
  header?: React.ReactNode;
}

const Card: React.FC<NFTCardProps> = ({
  data,
  image,
  name,
  header,
  children,
}) => {
  return (
    <Wrapper>
      <div>
        {image ?? (
          <img
            style={{ width: "100%", height: "100%" }}
            src={data?.preview.url}
            alt={data?.name}
          />
        )}
        {header}
      </div>

      <Content>
        {name ?? (
          <h3 style={{ margin: 0, textAlign: "center" }}>{data?.name}</h3>
        )}

        {children}
      </Content>
    </Wrapper>
  );
};

const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
`;

const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Actions = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-height: 30px;
`;

const Overlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 0;
  z-index: 1;
  text-align: center;
  transition: ${theme.transition};
`;

const Tag = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  right: 0;
  top: 0;
  padding: 0.5rem 0.75rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  background: white;
  opacity: 0.6;
  transition: ${theme.transition};
`;

const NFTCardFactory = {
  Card,
  Tag,
  Buttons,
  ContentRow,
  Actions,
  Overlay,
};

const NFTCard = NFTCardFactory;

export { NFTCard };

const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 0.5rem;

  background: white;
  border-radius: 1rem;

  box-sizing: border-box;
  background-clip: padding-box; /* !importanté */
  border: solid 5px transparent;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -5px; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: white;
  }

  &:hover {
    &:before {
      background-image: linear-gradient(
        45deg,
        #ffe6b2,
        #ffc7c8 25%,
        #debfff 50%,
        #c8deff 75%,
        #e1ffcd
      );
    }
  }

  & img {
    border-radius: 0.7rem;
    width: 100%;
    overflow: hidden;
  }

  &:hover ${Tag} {
    opacity: 1;
  }

  &:hover ${Overlay} {
    opacity: 0.95;
  }
`;

const Content = styled.div`
  padding: 1rem;

  display: grid;
  grid-template-rows: auto 1fr;
  gap: 0.75rem;
`;
