import { motion, AnimatePresence } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import NFTContract from "../../compiled-contracts/marketplace/NFT.json";
import { Grid, Spinner, Vendor } from "../../components";
import { useContractQuery } from "../contracts";
import { Listing, LoadingImage, NFTCard, NFTTokenURI } from "../marketplace";

const CollectionCard: React.FC<{ collection: Listing[] }> = ({
  collection,
}) => {
  const nftDataQuery = useContractQuery(
    NFTContract,
    collection[0].nftContract,
    async (contract) => {
      const tokenID = Number(collection[0].tokenId);

      const url = await contract.methods.uri(tokenID).call();
      const res = await fetch(url);

      if (res.ok) {
        return res.json();
      }
    }
  );

  const nft: NFTTokenURI = nftDataQuery.data;

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} layout>
      <Link to={`/night-market/market?collection=${collection[0].nftContract}`}>
        <NFTCard.Card
          name={<Vendor address={nft?.vendor} />}
          image={
            <LoadingImage>
              <AnimatePresence>
                {nft ? (
                  <motion.img
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.15 }}
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    src={nft.preview}
                    alt={nft.name}
                  />
                ) : (
                  <Spinner />
                )}
              </AnimatePresence>
            </LoadingImage>
          }
        >
          <Grid>
            <h2
              style={{
                textAlign: "center",
                marginTop: "-0.5rem",
                marginBottom: "0.5rem",
                fontSize: "16pt",
              }}
            >
              {nft?.name || <Spinner />}
            </h2>
          </Grid>
        </NFTCard.Card>
      </Link>
    </motion.div>
  );
};

export { CollectionCard };
