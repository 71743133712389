import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { ExhibitionsPage } from "./types";

type ExhibitionsPageData = {
  exhibitionsPage: ExhibitionsPage;
};

type ExhibitionsPageVariables = {
  id: string;
};

export const useExhibitionsPage = (
  options?: QueryHookOptions<ExhibitionsPageData, ExhibitionsPageVariables>
) => {
  return useQuery<ExhibitionsPageData, ExhibitionsPageVariables>(
    EXHIBITIONS_PAGE,
    options
  );
};

export const EXHIBITIONS_PAGE = gql`
  query ExhibitionsPage($id: ID!) {
    exhibitionsPage(where: { id: $id }) {
      id
      heading
      description
      approveAdmissionHelperText
      activeNfts {
        name
        preview {
          url
        }
        description
        kuroAddress
        nftAddress
        nftStakeAddress
        arrayHelperAddress
        rewardsPerDay
        comingSoon
        batchLength
        ticker
        tokenFloat
      }
      inactiveNfts {
        name
        preview {
          url
        }
        description
        kuroAddress
        nftAddress
        nftStakeAddress
        rewardsPerDay
        comingSoon
        batchLength
        ticker
        tokenFloat
      }
    }
  }
`;
