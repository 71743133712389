export const MARKETPLACE_CONTRACT_ADDRESS =
  "0xae00e2F72008fca61Aa4Aa99bd3Ce07552FCbb87"; /* MAINNET */
// "0x98d719D52cD01a465633DAfFd39598E0DeB8606c"; /* TESTNET */

export const KURO_CONTRACT_ADDRESS =
  "0x3E018675c0Ef63eB361b9EF4bfEa3A3294C74C7b"; /* MAINNET */
//"0xcB919A6f4555704b80F1345925E07e948f6FBb01"; /* TESTNET */

export const HARMONY_RPC_URL = "https://api.s0.t.hmny.io/"; /* MAINNET */
// "https://api.s0.b.hmny.io"; /* TESTNET */

export const HARMONY_EXPLORER_URL = "https://explorer.harmony.one";
// "https://explorer.pops.one"; /* TESTNET */

export const BUY_KURO_LINK =
  "https://game.defikingdoms.com/#/marketplace?outputCurrency=0x3e018675c0ef63eb361b9ef4bfea3a3294c74c7b";
