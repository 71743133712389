import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { MintPage } from "./types";

type MintPageData = {
  mintPage: MintPage;
};

type MintPageVariables = {
  id: string;
};

export const useMintPage = (
  options?: QueryHookOptions<MintPageData, MintPageVariables>
) => {
  return useQuery<MintPageData, MintPageVariables>(MINT_PAGE, options);
};

export const MINT_PAGE = gql`
  query mintPage($id: ID!) {
    mintPage(where: { id: $id }) {
      id
      heading
      nfts {
        nftAddress
        stakeAddress
        mintAddress
      }
    }
  }
`;
