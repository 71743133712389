import { useCallback, useEffect, useState } from "react";

const useApiQuery = (ROUTE: string) => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const fetchQuery = useCallback(async () => {
    setLoading(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_MARKETPLACE_SERVICE_API_URL}${ROUTE}`
      );

      if (res.ok) {
        const json = await res.json();
        setData(json);
        setLoading(false);
      } else {
        console.log(res);
        setError("Contract error");
      }
    } catch (error: Error | any) {
      console.log(error);
      setLoading(false);
      if ("message" in error) setError("Network Error: " + error.message);
    }
  }, [ROUTE]);

  useEffect(() => {
    fetchQuery();
  }, [fetchQuery]);

  return { fetch: fetchQuery, data, loading, error };
};

export { useApiQuery };
