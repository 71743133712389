import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

interface FlexProps extends React.HTMLProps<HTMLDivElement> {
  gap?: number;
}

const Flex = styled(motion.div)<
  FlexProps &
    Pick<React.CSSProperties, "justifyContent" | "alignItems" | "flexWrap">
>`
  display: flex;

  gap: ${({ gap }) => (gap ? `${gap}rem` : undefined)};

  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  flex-wrap: ${({ flexWrap }) => flexWrap};

  /* Gap Polyfill */

  column-gap: ${({ gap }) => (gap ? `${gap}rem` : undefined)};
  row-gap: ${({ gap }) => (gap ? `${gap}rem` : undefined)};
  margin: ${({ gap }) => (gap ? `calc(${gap}rem / -2)` : undefined)};

  & > * {
    margin: ${({ gap }) => (gap ? `calc(${gap}rem / 2)` : undefined)};
  }
`;

const Stack = styled(Flex)`
  flex-direction: column;
`;

export { Flex, Stack };
