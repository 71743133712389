import React from "react";
import styled from "styled-components";

const Logo = () => {
  return (
    <StyledLogo
      src="https://uploads-ssl.webflow.com/6180ece27918595d53453882/6180ed7ba3d4210fe49a9921_kuro_pant_icon_v01_fast.gif"
      alt="KURO Shiba Logo"
    />
  );
};

export { Logo };

const StyledLogo = styled.img`
  width: 100%;

  @keyframes wiggle {
    from {
      transform: rotate(-5deg);
    }
    to {
      transform: rotate(5deg);
    }
  }

  &:hover {
    animation: wiggle 0.1s linear infinite alternate;
  }
`;
