import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import styled from "styled-components";
import { useFooters } from "../content";
import { Nav, Socials } from "../navigation";
import KuroPrice from "../shared/KuroPrice";
import { Wallet } from "../wallet";

const MobileNav = () => {
  const { data } = useFooters();

  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const footer = data?.footers[0];

  return (
    <>
      <div
        style={{ width: 30 }}
        onClick={() => {
          setMenuOpen(!isMenuOpen);
        }}
      >
        <MenuIcon />
      </div>

      <AnimatePresence>
        {isMenuOpen && (
          <Menu>
            <Bg
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => {
                setMenuOpen(false);
              }}
            />
            <Sidebar
              initial={{ right: "-100%" }}
              animate={{ right: "0%" }}
              exit={{ right: "-100%" }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
              <Content>
                <Header>
                  <Wallet />
                  <div
                    style={{ width: 30 }}
                    onClick={() => {
                      setMenuOpen(false);
                    }}
                  >
                    <CloseIcon />
                  </div>
                </Header>
                <Nav />
                <KuroPrice />
              </Content>
              <Footer>{footer && <Socials {...{ footer }} />}</Footer>
            </Sidebar>
          </Menu>
        )}
      </AnimatePresence>
    </>
  );
};

export { MobileNav };

const Menu = styled.div`
  display: flex;
  position: fixed;

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  z-index: 1;
`;

const Sidebar = styled(motion.nav)`
  position: absolute;

  display: grid;
  grid-template-rows: 1fr auto;

  height: 100%;

  background: white;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  padding: 2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
`;

const Footer = styled.div`
  background: #000;

  padding: 2rem;
`;

const Bg = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;

  top: 0;
  left: 0;

  background: rgba(0, 0, 0, 0.15);
`;

const MenuIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
        clipRule="evenodd"
      />
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  );
};
