import styled from "styled-components";
import { theme } from "../utils/theme";

interface ButtonProps {
  dark?: boolean;
  size?: "small";
  loading?: boolean;
  loadingText?: string;
}

const Button = styled.button.attrs<ButtonProps>(
  ({ loading, loadingText, children }) => {
    return {
      children: loading ? (
        <>
          {loadingText || children}
          <Spinner />
        </>
      ) : (
        children
      ),
    };
  }
)<ButtonProps>`
  /* Reset */
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize 'line-height'. Cannot be changed from 'normal' in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable 'input' types in iOS */
  -webkit-appearance: none;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  ${({ dark }) => dark && { background: "black", color: "white" }};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  font-size: 12pt;
  padding: 1rem 1.75rem;

  ${({ size }) =>
    size === "small" && { fontSize: "10pt", padding: "0.5rem 1.5rem" }};

  text-transform: uppercase;

  border-radius: 8rem;
  border: 2px solid black;

  &:hover {
    background-image: linear-gradient(
      45deg,
      #ffe6b2,
      #ffc7c8 25%,
      #debfff 50%,
      #c8deff 75%,
      #e1ffcd
    );

    color: black;
  }

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }

  @media (max-width: ${theme.breakpoints.xs}px) {
    font-size: 11pt;
    padding: 0.75rem 1.5rem;

    ${({ size }) =>
      size === "small" && { fontSize: "9pt", padding: "0.25rem 0.75rem" }};
  }
`;

export { Button };

export const Spinner = () => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ animation: "rotate 1s linear infinite" }}
    >
      <path
        d="M1 5C1 7.20914 2.79086 9 5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1"
        stroke="currentColor"
        stroke-linecap="round"
      />
    </svg>
  );
};
