import { atom } from "recoil";

export const walletAuthorisedState = atom<boolean>({
  key: "walletAuthorisedState",
  default: false,
});

export const isHarmonyNetworkConnected = atom<boolean>({
  key: "isHarmonyNetworkConnected",
  default: false,
});

export const ethAddressState = atom<string | undefined>({
  key: "ethAddressState",
  default: undefined,
});
