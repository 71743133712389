import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import NFTContract from "../compiled-contracts/marketplace/NFT.json";
import MintContract from "../compiled-contracts/mint/Mint.json";
import KuroContract from "../compiled-contracts/utils/Kuro.json";
import {
  Anchor,
  Button,
  Error,
  FadeIn,
  H2,
  Loading,
  Section,
  Spinner,
} from "../components";
import { NFTv2, useMintPage, usePageIds } from "../features/content";
import { useContractMutation, useContractQuery } from "../features/contracts";
import {
  LoadingImage,
  NFTCard,
  NFTsSection,
  NFTTokenURI,
} from "../features/marketplace";
import { Layout, useAlert } from "../features/shared";
import {
  ethAddressState,
  isHarmonyNetworkConnected,
  walletAuthorisedState,
} from "../features/wallet/WalletState";
import { KURO_CONTRACT_ADDRESS, toSmallN } from "../utils";

const Mint = () => {
  const { data, loading, error } = usePageIds();

  if (error)
    return (
      <Layout>
        <Error />
      </Layout>
    );
  if (loading || !data)
    return (
      <Layout>
        <Loading />
      </Layout>
    );

  const mintPageId = data.mintPages[0].id;

  return (
    <Layout>
      <Helmet>
        <title>Mint your NFTs</title>
      </Helmet>
      <Content id={mintPageId} />
    </Layout>
  );
};

export { Mint };

const Content: React.FC<{ id: string }> = ({ id }) => {
  const { data, loading, error } = useMintPage({ variables: { id } });

  if (error) return <Error />;
  if (loading || !data) return <Loading />;

  const { mintPage } = data;

  return (
    <AnimatePresence>
      <Section>
        <FadeIn>
          <Wrapper>
            <H2 as="h1" gradient style={{ textAlign: "center" }}>
              {mintPage.heading}
            </H2>
            <NFTsSection
              nfts={mintPage.nfts}
              content={({ data }) =>
                `stakeAddress` in data &&
                data.mintAddress && <NFTContent {...{ data }} />
              }
            />
          </Wrapper>
        </FadeIn>
      </Section>
    </AnimatePresence>
  );
};

const { Card, Actions, ContentRow, Overlay } = NFTCard;

const NFTContent: React.FC<{ data: NFTv2 }> = ({ data }) => {
  const alert = useAlert();
  const ethAddress = useRecoilValue(ethAddressState);
  const walletAuthorised = useRecoilValue(walletAuthorisedState);
  const harmonyNetworkConnected = useRecoilValue(isHarmonyNetworkConnected);

  const nftDataQuery = useContractQuery(
    NFTContract,
    data.nftAddress,
    async (contract) => {
      const url = await contract.methods.uri(0 /* Hardcoded */).call();
      const res = await fetch(url);

      if (res.ok) {
        return res.json();
      }
    }
  );

  const mintFeeQuery = useContractQuery(
    MintContract,
    data.mintAddress as string,
    async (contract) => {
      return await contract.methods.getCost().call();
    }
  );

  const userAllowanceQuery = useContractQuery(
    KuroContract,
    KURO_CONTRACT_ADDRESS,
    async (contract, ethAddress) => {
      return await contract.methods
        .allowance(ethAddress, data.mintAddress)
        .call();
    }
  );

  const totalMintedQuery = useContractQuery(
    MintContract,
    data.mintAddress as string,
    async (contract) => {
      return await contract.methods.totalMinted().call();
    }
  );

  const maxSupplyQuery = useContractQuery(
    MintContract,
    data.mintAddress as string,
    async (contract) => {
      return await contract.methods.getSupply().call();
    }
  );

  const approveMintNFTMutation = useContractMutation(
    KuroContract,
    KURO_CONTRACT_ADDRESS,
    async (contract, ethAddress) => {
      return await contract.methods
        .approve(data.mintAddress, Number(mintFeeQuery.data))
        .send({ from: ethAddress });
    },
    {
      onCompleted: async () => {
        await userAllowanceQuery.fetch();
      },
    }
  );

  const mintNFTMutation = useContractMutation(
    MintContract,
    data.mintAddress as string,
    async (contract, ethAddress) => {
      const nftAddress = await contract.methods.nftToken().call();
      return await contract.methods.mint(nftAddress).send({ from: ethAddress });
    },
    {
      onCompleted: async () => {
        await totalMintedQuery.fetch();

        alert.open({
          children: (
            <div>
              <span>
                Successfully minted! Check out{" "}
                <Anchor as={Link} to="/night-market/assets">
                  Night Market{" "}
                </Anchor>
                to see your NFTs.
              </span>
            </div>
          ),
          timeout: 5000,
          dismissable: true,
        });
      },
    }
  );

  /* Variables */

  const nft: NFTTokenURI = nftDataQuery.data;

  async function handleApproveMintNFT() {
    await approveMintNFTMutation.mutate({});
  }

  async function handleMintNFT() {
    await mintNFTMutation.mutate({});
  }

  const OverlayContent = () => {
    if (!harmonyNetworkConnected)
      return (
        <Overlay>
          <h3>Connect to Harmony</h3>
        </Overlay>
      );
    if (!walletAuthorised || !ethAddress)
      return (
        <Overlay>
          <h3>Connect Wallet</h3>
        </Overlay>
      );
    return null;
  };

  const MutationErrors = () => {
    if (!approveMintNFTMutation.error || !mintNFTMutation.error) return null;
    return (
      <ErrorWrapper>
        {approveMintNFTMutation.error || mintNFTMutation.error}
      </ErrorWrapper>
    );
  };

  return (
    <Card
      image={
        <LoadingImage>
          <AnimatePresence>
            {nft ? (
              <motion.img
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.15 }}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                src={nft.preview}
                alt={nft.name}
              />
            ) : (
              <Spinner />
            )}
          </AnimatePresence>
        </LoadingImage>
      }
    >
      <h2
        style={{
          textAlign: "center",
          marginTop: "-0.5rem",
          fontSize: "16pt",
        }}
      >
        {nft?.name || <Spinner />}
      </h2>

      <div style={{ textAlign: "center", marginBottom: "1rem" }}>
        {nft?.description}
      </div>

      <div>
        <ContentRow>
          <span>Minted</span>
          <span>
            {totalMintedQuery.data} / {maxSupplyQuery.data}
          </span>
        </ContentRow>
        <ContentRow>
          <span>Cost</span>
          <span>{toSmallN(mintFeeQuery.data, 9).toLocaleString()} KURO</span>
        </ContentRow>
      </div>

      <Actions>
        <OverlayContent />
        {Number(userAllowanceQuery.data) < Number(mintFeeQuery.data) ? (
          <Button
            dark
            onClick={handleApproveMintNFT}
            disabled={
              approveMintNFTMutation.loading ||
              Number(totalMintedQuery.data) >= Number(maxSupplyQuery.data)
            }
            loading={approveMintNFTMutation.loading}
            loadingText="Approving"
          >
            Approve
          </Button>
        ) : (
          <Button
            dark
            onClick={handleMintNFT}
            disabled={
              mintNFTMutation.loading ||
              Number(totalMintedQuery.data) >= Number(maxSupplyQuery.data)
            }
            loading={mintNFTMutation.loading}
            loadingText="Minting"
          >
            Mint
          </Button>
        )}
        <MutationErrors />
      </Actions>
    </Card>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding-bottom: 8rem;
`;

const ErrorWrapper = styled.div`
  text-align: center;
  padding: 1rem;
  background: #ffc7c8;
  border-radius: 5px;
  margin-top: 0.5rem;
`;
