import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import KuroMining from "../assets/kuro-mining.gif";
import { Fade } from "../components";

const Loading = () => {
  const [isVisible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <AnimatePresence>
      <Wrapper>
        {isVisible && (
          <Fade>
            <Image src={KuroMining} alt="Loading" />
          </Fade>
        )}
      </Wrapper>
    </AnimatePresence>
  );
};

export { Loading };

const Wrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  width: 8rem;
`;
