import Web3 from "web3";
import { AbiItem } from "web3-utils";

export async function initContract(
  provider: Web3,
  contract: any | { abi: AbiItem | AbiItem[] },
  address: string
) {
  const contractInstance = new provider.eth.Contract(contract.abi, address);
  return contractInstance;
}
