import React from "react";
import styled from "styled-components";

const MutationErrors: React.FC = ({ children }) => {
  if (!children) return null;
  return <ErrorWrapper>{children}</ErrorWrapper>;
};

export { MutationErrors };

const ErrorWrapper = styled.div`
  text-align: center;
  padding: 1rem;
  background: #ffc7c8;
  border-radius: 5px;
  margin-top: 0.5rem;
`;
