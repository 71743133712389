import styled from "styled-components";

const Input = styled.input`
  all: unset;

  font-size: 12pt;
  padding: 0.5rem 0.75rem;

  border-radius: 0.2rem;
  border: 2px solid #dddddd;
`;

export { Input };
