import { Hero } from "./Hero";
import { Summary } from "./Summary";
import { Roadmap } from "./Roadmap";
import { JoinTheClub } from "./JoinTheClub";

const SectionsFactory = {
  Hero,
  Summary,
  Roadmap,
  JoinTheClub,
};

const Sections = SectionsFactory;
export { Sections };
