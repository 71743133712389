import React, { useState } from "react";
import styled from "styled-components";
import NFTContract from "../../compiled-contracts/marketplace/NFT.json";
import { Button, Tag } from "../../components";
import { Close } from "../../components/icons";
import { useContractMutation, useContractQuery } from "../contracts";
import { NFTCard } from "../marketplace";
import { Modal } from "../shared";

const { Actions, Buttons } = NFTCard;

interface StakeModalProps {
  nftAddress: string;
  closeModal: () => void;
  onCompleted: () => Promise<void>;
}

const StakeModal: React.FC<StakeModalProps> = ({
  nftAddress,
  closeModal,
  onCompleted,
}) => {
  const [selectedTokens, setSelectedTokens] = useState<number[]>([]);

  const ownedTokensQuery = useContractQuery(
    NFTContract,
    nftAddress,
    async (contract, ethAddress) => {
      return await contract.methods
        .getTokensAvailableForTransfer(ethAddress)
        .call();
    }
  );

  const stakeMultipleNFTsMutation = useContractMutation(
    NFTContract,
    nftAddress,
    async (contract, ethAddress, { tokenIDs }) => {
      return await contract.methods
        .stakeMultipleNFTs(tokenIDs)
        .send({ from: ethAddress });
    },
    {
      onCompleted,
    }
  );

  const ownedTokens: number[] = ownedTokensQuery.data?.map((i: string) =>
    Number(i)
  );

  async function handleStakeMultipleNFTs() {
    await stakeMultipleNFTsMutation.mutate({ tokenIDs: selectedTokens });
    closeModal();
  }

  if (!ownedTokens) return null;

  return (
    <Modal.Content>
      <Modal.Header>
        <strong>Choose NFTs to Stake</strong>
        <div style={{ width: 20, cursor: "pointer" }} onClick={closeModal}>
          <Close />
        </div>
      </Modal.Header>

      <SelectTokens>
        {ownedTokens.map((i) => {
          const isSelected = selectedTokens.includes(i);

          function handleToggleTag() {
            if (isSelected) {
              setSelectedTokens(
                selectedTokens.filter((tokenID) => tokenID !== i)
              );
            } else {
              setSelectedTokens([...selectedTokens, i]);
            }
          }

          return (
            <Tag key={i} {...{ isSelected }} onClick={handleToggleTag}>
              #{i}
            </Tag>
          );
        })}
      </SelectTokens>

      <Actions>
        <Buttons>
          <Button
            size="small"
            onClick={() => setSelectedTokens([])}
            disabled={selectedTokens.length === 0}
          >
            Clear
          </Button>
          <Button
            size="small"
            onClick={() => setSelectedTokens(ownedTokens)}
            disabled={selectedTokens.length === ownedTokens.length}
          >
            Select All
          </Button>
        </Buttons>

        <Button
          dark
          onClick={handleStakeMultipleNFTs}
          loading={stakeMultipleNFTsMutation.loading}
          disabled={
            selectedTokens.length === 0 || stakeMultipleNFTsMutation.loading
          }
          loadingText="Staking"
        >
          Stake Selected ({selectedTokens.length})
        </Button>
      </Actions>
    </Modal.Content>
  );
};

export { StakeModal };

const SelectTokens = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: #f1f1f1;
  padding: 0.5rem;
  border-radius: 0.5rem;
  gap: 0.5rem;
  width: 300px;
  overflow: auto;

  max-height: 300px;
`;
