import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Listing, NFTCard, NFTTokenURI } from ".";
import NFTContract from "../../compiled-contracts/marketplace/NFT.json";
import {
  Button,
  Flex,
  Grid,
  Responsive,
  Spinner,
  Stack,
  Vendor,
} from "../../components";
import { toSmallN } from "../../utils";
import { theme } from "../../utils/theme";
import { useContractQuery } from "../contracts";
import { Logo } from "../shared";

interface ListingCardProps {
  listing: Listing;
  owned?: boolean;
}

const ListingCard: React.FC<ListingCardProps> = ({ listing, owned }) => {
  const nftDataQuery = useContractQuery(
    NFTContract,
    listing.nftContract,
    async (contract) => {
      const tokenID = Number(listing.tokenId);

      const url = await contract.methods.uri(tokenID).call();
      const res = await fetch(url);

      if (res.ok) {
        return res.json();
      }
    }
  );

  const nft: NFTTokenURI = nftDataQuery.data;

  const Price = () => {
    return (
      <Flex alignItems="center" gap={0.25}>
        <div style={{ width: 30, height: 30 }}>
          <Logo />
        </div>
        <p>{toSmallN(Number(listing.price), 9).toLocaleString()} KURO</p>
      </Flex>
    );
  };

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} layout>
      <Link
        to={
          owned
            ? `/night-market/assets`
            : `/night-market/nft/${listing.nftContract}/${listing.tokenId}`
        }
      >
        <NFTCard.Card
          header={owned && <NFTCard.Tag>Owned</NFTCard.Tag>}
          name={<Vendor address={nft?.vendor} />}
          image={
            <LoadingImage>
              <AnimatePresence>
                {nft ? (
                  <motion.img
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.15 }}
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    src={nft.preview}
                    alt={nft.name}
                  />
                ) : (
                  <Spinner />
                )}
              </AnimatePresence>
            </LoadingImage>
          }
        >
          <Grid>
            <h2
              style={{
                textAlign: "center",
                marginTop: "-0.5rem",
                fontSize: "16pt",
              }}
            >
              {nft?.name || <Spinner />} #{listing.tokenId}
            </h2>

            <Responsive
              xs={
                <Stack alignItems="center" gap={0.5}>
                  <Price />

                  <Button
                    dark
                    disabled={!nft || owned}
                    style={{ width: "100%" }}
                  >
                    Buy
                  </Button>
                </Stack>
              }
              md={
                <Grid
                  justifyContent="center"
                  gridTemplateColumns="auto 1fr"
                  gap={0.5}
                >
                  <Price />

                  <Button size="small" dark disabled={!nft || owned}>
                    Buy
                  </Button>
                </Grid>
              }
            />
          </Grid>
        </NFTCard.Card>
      </Link>
    </motion.div>
  );
};

export { ListingCard };

export const LoadingImage = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 0.75rem;
  background: #fafafa;
  width: 100%;

  min-height: calc(${theme.maxPageWidth / 4}px + 2.25rem);

  @media (max-width: ${theme.maxPageWidth + 256}px) {
    /* offsets Section margin */
    min-height: calc(33vh + 2.25rem);
  }

  @media (max-width: ${theme.breakpoints.xs}px) {
    min-height: calc(50vw - 2rem);
  }

  @media (max-width: ${theme.breakpoints.tiny}px) {
    min-height: calc(100vw - 2rem);
  }
`;
