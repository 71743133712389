import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { modalState } from "./ModalState";

const Layout = () => {
  const [modal, setModal] = useRecoilState(modalState);

  function closeModal() {
    setModal(undefined);
  }

  return (
    <AnimatePresence>
      {modal && (
        <Wrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.15, delay: 0.15, ease: "easeInOut" }}
        >
          <Bg onClick={closeModal} />
          <Container>{modal?.children?.(closeModal)}</Container>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ModalFactory = {
  Layout,
  Content,
  Header,
};

const Modal = ModalFactory;

export { Modal, Content, Header };

const Wrapper = styled(motion.div)`
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Bg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #caa3ff;
  opacity: 0.6;
`;

const Container = styled.div`
  z-index: 10;
  background: white;
  padding: 1rem;
  border-radius: 1rem;
`;
