import React from "react";

const Vendor: React.FC<{ address?: string }> = ({ address }) => {
  return (
    <div style={{ maxWidth: "100%", overflowX: "auto", textAlign: "center" }}>
      <span style={{ fontSize: "10pt" }}>{address || "Kuro Shiba"}</span>
    </div>
  );
};

export { Vendor };
