import styled from "styled-components";
import { Grid } from "../../components";
import { theme } from "../../utils/theme";

const CardGrid: React.FC = ({ children }) => {
  return <StyledCardGrid gap={0.5}>{children}</StyledCardGrid>;
};

const StyledCardGrid = styled(Grid)`
  justify-content: center;
  grid-template-columns: repeat(4, 1fr);

  width: ${theme.maxPageWidth}px;

  @media (max-width: ${theme.maxPageWidth + 256}px) {
    /* offsets Section margin */
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${theme.breakpoints.xs}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${theme.breakpoints.tiny}px) {
    grid-template-columns: 1fr;
  }
`;

export { CardGrid };
