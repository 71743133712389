import styled from "styled-components";
import { theme } from "../utils/theme";

const H1 = styled.h1<{ gradient?: boolean }>`
  font-size: 8rem;
  line-height: 1;

  margin: 0;

  ${({ gradient }) => {
    return (
      gradient && {
        fontFamily: "'Fredoka One'",
        backgroundImage:
          "linear-gradient(45deg, #ffd782, #ffa6a8 25%, #bf83ff 50%, #8ab8ff 75%, #b3ff81)",
        textShadow: "8px -8px 0 #fff",

        WebkitBackgroundClip: "text",
        backgroundClip: "text",
        WebkitTextFillColor: "transparent",
      }
    );
  }};

  @media (max-width: ${theme.breakpoints.sm}px) {
    font-size: 6rem;
  }

  @media (max-width: ${theme.breakpoints.xs}px) {
    font-size: 5rem;
  }
`;

export { H1 };
