import { ApolloProvider } from "@apollo/client";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import "./index.css";
import {
  Airmail,
  ArtMuseum,
  Chart,
  Exhibitions,
  FishPonds,
  Landing,
  Mint,
  NFTDetail,
  NightMarket,
  NoMatch,
} from "./routes";
import { client } from "./utils";

const App = () => {
  return (
    <RecoilRoot>
      <ApolloProvider {...{ client }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/mint" element={<Mint />} />
            <Route path="/museum" element={<ArtMuseum />} />
            <Route path="/exhibitions" element={<Exhibitions />} />
            <Route path="/ponds" element={<FishPonds />} />
            <Route path="/night-market/*" element={<NightMarket />} />
            <Route path="/chart" element={<Chart />} />
            <Route path="/airmail" element={<Airmail />} />

            <Route
              path="/night-market/nft/:address/:tokenID"
              element={<NFTDetail />}
            />

            <Route path="*" element={<NoMatch />} />
          </Routes>
        </BrowserRouter>
      </ApolloProvider>
    </RecoilRoot>
  );
};

export default App;
