import React, { useState } from "react";
import styled from "styled-components";
import NFTStakeContract from "../../compiled-contracts/marketplace/NFTStake.json";
import { Button, Tag } from "../../components";
import { Close } from "../../components/icons";
import { useContractMutation, useContractQuery } from "../contracts";
import { NFTCard } from "../marketplace";
import { Modal } from "../shared";

const { Actions, Buttons } = NFTCard;

interface UnstakeModalProps {
  stakeAddress: string;
  closeModal: () => void;
  onCompleted: () => Promise<void>;
}

const UnstakeModal: React.FC<UnstakeModalProps> = ({
  stakeAddress,
  closeModal,
  onCompleted,
}) => {
  const [selectedTokens, setSelectedTokens] = useState<number[]>([]);

  const NFTsUserStakedQuery = useContractQuery(
    NFTStakeContract,
    stakeAddress,
    async (contract, ethAddress) => {
      return await contract.methods.getAllNFTsUserStaked(ethAddress).call();
    }
  );

  const unstakeMultipleNFTsMutation = useContractMutation(
    NFTStakeContract,
    stakeAddress,
    async (contract, ethAddress, { tokenIDs }) => {
      return await contract.methods
        .unstakeMultipleNFTs(tokenIDs)
        .send({ from: ethAddress });
    },
    {
      onCompleted,
    }
  );

  const stakedTokens: number[] | undefined = NFTsUserStakedQuery.data?.map(
    (tokenID: string) => {
      return Number(tokenID);
    }
  );

  async function handleUnstakeMultipleNFTs() {
    await unstakeMultipleNFTsMutation.mutate({ tokenIDs: selectedTokens });
    closeModal();
  }

  if (!stakedTokens) return null;

  return (
    <Modal.Content>
      <Modal.Header>
        <strong>Choose NFTs to Unstake</strong>
        <div style={{ width: 20, cursor: "pointer" }} onClick={closeModal}>
          <Close />
        </div>
      </Modal.Header>

      <SelectTokens>
        {stakedTokens.map((i) => {
          const isSelected = selectedTokens.includes(i);

          function handleToggleTag() {
            if (isSelected) {
              setSelectedTokens(
                selectedTokens.filter((tokenID) => tokenID !== i)
              );
            } else {
              setSelectedTokens([...selectedTokens, i]);
            }
          }

          return (
            <Tag key={i} {...{ isSelected }} onClick={handleToggleTag}>
              #{i}
            </Tag>
          );
        })}
      </SelectTokens>

      <Actions>
        <Buttons>
          <Button
            size="small"
            onClick={() => setSelectedTokens([])}
            disabled={selectedTokens.length === 0}
          >
            Clear
          </Button>
          <Button
            size="small"
            onClick={() => setSelectedTokens(stakedTokens)}
            disabled={selectedTokens.length === stakedTokens.length}
          >
            Select All
          </Button>
        </Buttons>

        <Button
          dark
          onClick={handleUnstakeMultipleNFTs}
          loading={unstakeMultipleNFTsMutation.loading}
          disabled={
            selectedTokens.length === 0 || unstakeMultipleNFTsMutation.loading
          }
          loadingText="Unstaking"
        >
          Unstake Selected ({selectedTokens.length})
        </Button>
      </Actions>
    </Modal.Content>
  );
};

export { UnstakeModal };

const SelectTokens = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: #f1f1f1;
  padding: 0.5rem;
  border-radius: 0.5rem;
  gap: 0.5rem;
  width: 300px;
  overflow: auto;

  max-height: 300px;
`;
