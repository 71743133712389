import React from "react";
import { Helmet } from "react-helmet";
import { Error, Loading } from "../components";
import { LandingPage, useLandingPage, usePageIds } from "../features/content";
import { Sections } from "../features/sections";
import { Layout } from "../features/shared";

const Landing = () => {
  const { data, loading, error } = usePageIds();

  if (error)
    return (
      <Layout>
        <Error />
      </Layout>
    );
  if (loading || !data)
    return (
      <Layout>
        <Loading />
      </Layout>
    );

  const landingPageId = data.landingPages[0].id;

  return (
    <Layout>
      <Helmet>
        <title>
          Kuro Shiba (KURO) - The First and Cutest Stakeable NFTs on Harmony!
        </title>
        <meta
          name="description"
          content="Kuro Shiba is the first (and cutest!) yield-generating NFT project on Harmony, aiming to strengthen the Harmony ecosystem through collaboration, innovation, and NFTs! ✨🍙🍡"
        />
      </Helmet>
      <Content id={landingPageId} />
    </Layout>
  );
};

const Content: React.FC<{ id: string }> = ({ id }) => {
  const { data, loading, error } = useLandingPage({ variables: { id } });

  if (error) return <Error />;
  if (loading || !data) return <Loading />;

  const { landingPage } = data;

  return (
    <>
      <Sections.Hero {...{ landingPage }} />
      <Sections.Summary {...{ landingPage }} />
      <Sections.Roadmap {...{ landingPage }} />
      <Sections.JoinTheClub {...{ landingPage }} />
    </>
  );
};

export interface LandingPageSectionProps {
  landingPage: LandingPage;
}

export { Landing };
