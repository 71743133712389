import { atom } from "recoil";

export type AlertProps = {
  isOpen: boolean;
  children?: React.ReactNode;
  timeout?: number;
  dismissable?: boolean;
};

export const initialAlert = {
  isOpen: false,
  children: null,
  timeout: 5000,
  dismissable: true,
};

export const alertState = atom<AlertProps>({
  key: "alertState",
  default: initialAlert,
});
