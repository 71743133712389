import React from "react";
// import Snowfall from "react-snowfall";
import styled from "styled-components";
import { Modal } from ".";
import { theme } from "../../utils/theme";
import { Footer, Header } from "../navigation";
import { Alert } from "./Alert";

const Layout: React.FC = ({ children }) => {
  return (
    <StyledLayout>
      <SnowWrapper>{/* <Snowfall snowflakeCount={30} /> */}</SnowWrapper>

      <Alert />
      <Modal.Layout />

      <Bg />
      <Header />
      <Content>{children}</Content>
      <Footer />
    </StyledLayout>
  );
};

export { Layout };

const StyledLayout = styled.main`
  position: relative;
  display: grid;
  grid-template-rows: auto minmax(100vh, 1fr) auto;

  min-height: calc(
    100vh + 200px
  ); /* estimate the height of the screen + footer to accomodate for the flash of 'no-content' on mobile */
`;

const SnowWrapper = styled.div`
  pointer-events: none;
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const Bg = styled.div`
  position: fixed;
  z-index: -1;

  width: 100%;
  height: 100%;

  background-image: linear-gradient(180deg, #e5d1ff 5%, #caa3ff 95%);
`;

const Content = styled.div`
  @media (max-width: ${theme.breakpoints.sm}px) {
    padding-top: 6rem;
  }
`;
