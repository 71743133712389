import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Fade, Responsive } from "../../components";
import { theme } from "../../utils/theme";
import { MobileNav, Nav } from "../navigation";
import { Logo } from "../shared";
import KuroPrice from "../shared/KuroPrice";
import { Wallet } from "../wallet";

const Header = () => {
  const [isVisible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <AnimatePresence>
      {isVisible && (
        <Fade>
          <StyledHeader>
            <Wrapper>
              <Link to="/">
                <div style={{ width: 60 }}>
                  <Logo />
                </div>
              </Link>
              <Responsive
                sm={<MobileNav />}
                md={
                  <Actions>
                    <StyledNav>
                      <Nav />
                    </StyledNav>
                    <KuroPrice />

                    <Wallet />
                  </Actions>
                }
              />
            </Wrapper>
          </StyledHeader>
        </Fade>
      )}
    </AnimatePresence>
  );
};

export { Header };

const StyledHeader = styled.header`
  display: flex;
  justify-content: center;

  padding: 1rem 2rem;

  z-index: 1;

  @media (max-width: ${theme.breakpoints.sm}px) {
    padding: 1rem;
    box-sizing: border-box;
    justify-content: flex-start;

    position: fixed;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  gap: 3rem;
`;
