import React from "react";
import styled from "styled-components";
import { formatCurrency } from "../../utils";
import { Logo, usePrice } from "../shared";
import { Link, useLocation } from "react-router-dom";

const KuroPrice = () => {
  const { price } = usePrice();
  const { pathname } = useLocation();

  return (
    <StyledKuroPrice>
      <div style={{ width: 25, height: 25 }}>
        <Logo />
      </div>
      <Navlink to="/chart" active={pathname.includes("chart")}>
        {formatCurrency(price?.now as number) || "..."}
      </Navlink>
    </StyledKuroPrice>
  );
};

export default KuroPrice;

const StyledKuroPrice = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Navlink = styled(Link)<{ active?: boolean }>`
  ${({ active }) => active && { color: "#a675ff" }};

  width: 51px; /* Prevents Header from changing sizes when price is loading. */

  &:hover {
    color: #a675ff;
  }
`;
