import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { LandingPage } from "./types";

type LandingPageData = {
  landingPage: LandingPage;
};

type LandingPageVariables = {
  id: string;
};

export const useLandingPage = (
  options?: QueryHookOptions<LandingPageData, LandingPageVariables>
) => {
  return useQuery<LandingPageData, LandingPageVariables>(LANDING_PAGE, options);
};

export const LANDING_PAGE = gql`
  query landingPage($id: ID!) {
    landingPage(where: { id: $id }) {
      id
      heading

      heroTagline
      heroImage {
        url
      }
      heroNftApplicationsArray

      summaryTitle
      summaryCards {
        id
        title
        description
        buttonText
        buttonPath
        image {
          url
        }
      }

      roadmapTitle
      roadmapCards {
        id
        title
        description
      }

      joinTheClubTitle
      joinTheClubButtonText
      joinTheClubLink
      joinTheClubImage {
        url
      }
    }
  }
`;
