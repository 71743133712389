import React from "react";
import { MemoryRouter, Routes } from "react-router-dom";

const TestProvider: React.FC = ({ children }) => {
  return (
    <MemoryRouter>
      <Routes>{children}</Routes>
    </MemoryRouter>
  );
};

export { TestProvider };
