import { AnimatePresence } from "framer-motion";
import React from "react";
import ReactTypingEffect from "react-typing-effect";
import styled from "styled-components";
import { FadeIn, H1, H3, Section, Button } from "../../../components";
import { LandingPageSectionProps } from "../../../routes";
import { formatCurrency } from "../../../utils";
import { theme } from "../../../utils/theme";
import { usePrice } from "../../shared";

const Hero: React.FC<LandingPageSectionProps> = ({ landingPage }) => {
  const { heroTagline, heroNftApplicationsArray } = landingPage;

  const { price } = usePrice();

  const marketCap = formatCurrency(price?.marketCap) || "Loading...";
  const circulatingSupply =
    price?.circulatingSupply.toLocaleString() || "Loading...";

  return (
    <AnimatePresence exitBeforeEnter>
      <Section>
        <FadeIn>
          <Wrapper>
            <Headings>
              <H1 gradient data-testid="primary-heading">
                Kuro Shiba
              </H1>
              <Tagline>
                {heroTagline}{" "}
                <ReactTypingEffect
                  text={heroNftApplicationsArray}
                  cursor="_"
                  speed={100}
                  eraseSpeed={100}
                  eraseDelay={1000}
                  typingDelay={1000}
                  cursorClassName="react-typing-effect-cursor"
                />
              </Tagline>
            </Headings>

            <WatermelonWrapper>
              <WatermelonSlice
                src="https://uploads-ssl.webflow.com/6180ece27918595d53453882/6188eda8cc7f19262f928a9a_!watermelon.png"
                alt="Watermelon Slice"
              />
              <WatermelonShiba
                src="https://uploads-ssl.webflow.com/6180ece27918595d53453882/6181e4947a2f5a6598b62625_KUROyawntiny.png"
                alt="Watermelon Shiba"
              />
            </WatermelonWrapper>

            <Buttons>
              <Button
                as="a"
                href="https://viperswap.one/#/swap?outputCurrency=0x3e018675c0ef63eb361b9ef4bfea3a3294c74c7b"
                target="_blank"
                rel="noreferrer"
              >
                Buy on Viperswap
              </Button>
              <Button
                as="a"
                href="https://game.defikingdoms.com/#/marketplace?outputCurrency=0x3e018675c0ef63eb361b9ef4bfea3a3294c74c7b"
                target="_blank"
                rel="noreferrer"
              >
                Buy on DEFI Kingdoms
              </Button>
            </Buttons>

            <Features>
              <Feature>
                <H3 shadow>{marketCap}</H3>
                <span>Market Cap</span>
              </Feature>

              <Feature>
                <H3 shadow>{circulatingSupply}</H3>
                <span>Circulating KURO</span>
              </Feature>
            </Features>
          </Wrapper>
        </FadeIn>
      </Section>
    </AnimatePresence>
  );
};

export { Hero };

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;

  @media (max-width: ${theme.breakpoints.sm}px) {
    margin-top: 0;
  }
`;

const Tagline = styled(H3)`
  @media (max-width: ${theme.breakpoints.xs}px) {
    /* resolves https://github.com/KuroShibaONE/kuro-dapp/issues/36 */
    display: grid;
    line-height: 1.2;
  }
`;

const Headings = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  text-align: center;

  & > * {
    margin: 0;
  }
`;

const Features = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
  row-gap: 2rem;
`;

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  row-gap: 1rem;
`;

const Feature = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  text-align: center;
`;

const WatermelonWrapper = styled.div`
  position: relative;
  height: 180px;
  width: 230px;
`;

const WatermelonSlice = styled.img`
  position: absolute;
  height: 80px;

  left: 0;
  top: 40px;

  transform: rotate(70deg);
`;

const WatermelonShiba = styled.img`
  position: absolute;
  height: 180px;

  right: 0;
`;
