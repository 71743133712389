import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { FishPondsPage } from "./types";

type FishPondsPageData = {
  fishPondsPage: FishPondsPage;
};

type FishPondsPageVariables = {
  id: string;
};

export const useFishPondsPage = (
  options?: QueryHookOptions<FishPondsPageData, FishPondsPageVariables>
) => {
  return useQuery<FishPondsPageData, FishPondsPageVariables>(
    FISH_PONDS_PAGE,
    options
  );
};

export const FISH_PONDS_PAGE = gql`
  query fishPondsPage($id: ID!) {
    fishPondsPage(where: { id: $id }) {
      id
      heading
      title
      description
      image {
        url
      }
    }
  }
`;
