import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import MarketplaceContract from "../../compiled-contracts/marketplace/Marketplace.json";
import NFTContract from "../../compiled-contracts/marketplace/NFT.json";
import KuroContract from "../../compiled-contracts/utils/Kuro.json";
import {
  Anchor,
  Button,
  Error,
  Flex,
  Grid,
  H2,
  H3,
  Loading,
  MutationErrors,
  Responsive,
  Section,
  Spinner,
  Stack,
  Tooltip,
  Vendor,
} from "../../components";
import { useApiQuery } from "../../features/api";
import {
  useContractMutation,
  useContractQuery,
} from "../../features/contracts";
import {
  Listing,
  NFTTokenURI,
  TransactionHistory,
} from "../../features/marketplace";
import { Layout, Logo, useAlert, usePrice } from "../../features/shared";
import {
  abbreviateETHAddress,
  BUY_KURO_LINK,
  groupBy,
  HARMONY_EXPLORER_URL,
  KURO_CONTRACT_ADDRESS,
  MARKETPLACE_CONTRACT_ADDRESS,
  toSmallN,
  toUSD,
} from "../../utils";
import { theme } from "../../utils/theme";

const NFTDetail = () => {
  const { address, tokenID } = useParams();

  const listingsQuery = useApiQuery("/listings/allForSale");

  if (listingsQuery.error)
    return (
      <Layout>
        <Error />
      </Layout>
    );
  if (listingsQuery.loading || !listingsQuery.data)
    return (
      <Layout>
        <Loading />
      </Layout>
    );

  const listings: Listing[] = listingsQuery.data;

  const listing = listings?.find(
    (listing) => listing.nftContract === address && listing.tokenId === tokenID
  );

  if (!listing)
    return (
      <Layout>
        <Error>We couldn't find this NFT. It may not be listed for sale.</Error>
      </Layout>
    );

  return <Layout>{listing && <Content {...{ listing }} />}</Layout>;
};

const Content: React.FC<{ listing: Listing }> = ({ listing }) => {
  const navigate = useNavigate();
  const alert = useAlert();
  const { price } = usePrice();
  const listingsQuery = useApiQuery("/listings/allForSale");

  /* Queries */

  const nftDataQuery = useContractQuery(
    NFTContract,
    listing.nftContract,
    async (contract) => {
      const tokenID = Number(listing.tokenId);

      const url = await contract.methods.uri(tokenID).call();
      const res = await fetch(url);

      if (res.ok) {
        return res.json();
      }
    }
  );

  const nftTotalSupplyQuery = useContractQuery(
    NFTContract,
    listing.nftContract,
    async (contract) => {
      return await contract.methods.supply().call();
    }
  );

  const isNFTApprovedQuery = useContractQuery(
    KuroContract,
    KURO_CONTRACT_ADDRESS,
    async (contract, ethAddress) => {
      const tokensApproved = await contract.methods
        .allowance(ethAddress, MARKETPLACE_CONTRACT_ADDRESS)
        .call();

      const isAllowed = tokensApproved >= Number(listing.price);

      return isAllowed;
    }
  );

  /* Mutations */

  const approveNFTMutation = useContractMutation(
    KuroContract,
    KURO_CONTRACT_ADDRESS,
    async (contract, ethAddress) => {
      return await contract.methods
        .approve(MARKETPLACE_CONTRACT_ADDRESS, Number(listing.price))
        .send({ from: ethAddress });
    },
    {
      onCompleted: async () => {
        await isNFTApprovedQuery.fetch();
      },
    }
  );

  const buyNFTMutation = useContractMutation(
    MarketplaceContract,
    MARKETPLACE_CONTRACT_ADDRESS,
    async (contract, ethAddress) => {
      return await contract.methods
        .purchaseNFT(
          listing.nftContract,
          Number(listing.tokenId),
          JSON.stringify({ seller: listing.seller })
        )
        .send({ from: ethAddress });
    },
    {
      onCompleted: async () => {
        alert.open("Successfully Purchased");
        navigate("/night-market/assets");
      },
    }
  );

  /* Helper Variables */

  if (nftDataQuery.error || listingsQuery.error) return <Error />;
  if (
    nftDataQuery.loading ||
    !nftDataQuery.data ||
    listingsQuery.loading ||
    !listingsQuery.data
  )
    return <Loading />;

  const nft: NFTTokenURI = nftDataQuery.data;
  const listings: Listing[] = listingsQuery.data;
  const collections = Object.entries(
    groupBy<string, Listing>(listings, "nftContract")
  );
  const collection = collections.filter(
    ([address, _]) => address === listing.nftContract
  )[0][1];
  const floorPrice = Math.min.apply(
    Math,
    collection.map((listing) => Number(listing.price))
  );

  async function handleApproveNFT() {
    await approveNFTMutation.mutate({});
  }

  async function handleBuyNFT() {
    await buyNFTMutation.mutate({});
  }

  return (
    <Section>
      <Stack gap={1}>
        <StyledRow gap={0.5}>
          <Responsive
            xs={<div />}
            md={
              <div>
                <img
                  src={nft.preview}
                  alt={nft.name}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "1rem",
                  }}
                />
              </div>
            }
          />

          <Card>
            <Grid
              gap={1}
              gridTemplateRows="1fr auto"
              style={{ height: "100%" }}
            >
              <Stack gap={1}>
                <Stack style={{ textAlign: "center", marginTop: "1rem" }}>
                  <Vendor address={nft?.vendor} />
                  <H3>{nft.name}</H3>
                </Stack>

                <Responsive
                  xs={
                    <div>
                      <img
                        src={nft.image}
                        alt={nft.name}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "1rem",
                        }}
                      />
                    </div>
                  }
                  md={<div />}
                />

                <p style={{ textAlign: "center" }}>{nft.description}</p>
              </Stack>

              <Stack gap={1.25}>
                <Flex alignItems="flex-end" gap={0.5} justifyContent="center">
                  <div style={{ width: 50, height: 50, margin: 0 }}>
                    <Logo />
                  </div>
                  <Tooltip
                    tooltip={
                      <div>
                        $
                        {price &&
                          toUSD(
                            toSmallN(Number(listing.price), 9),
                            price.now
                          ).toLocaleString()}{" "}
                        USD
                      </div>
                    }
                  >
                    <H2 darkGradient style={{ margin: 0, lineHeight: 1 }}>
                      {toSmallN(Number(listing.price), 9).toLocaleString()} KURO
                    </H2>
                  </Tooltip>
                </Flex>

                <Stack gap={0.5}>
                  {isNFTApprovedQuery.data ? (
                    <Button
                      loading={buyNFTMutation.loading}
                      disabled={buyNFTMutation.loading}
                      onClick={handleBuyNFT}
                      dark
                    >
                      Buy
                    </Button>
                  ) : (
                    <Button
                      loading={approveNFTMutation.loading}
                      disabled={approveNFTMutation.loading}
                      onClick={handleApproveNFT}
                      dark
                    >
                      Approve
                    </Button>
                  )}

                  <MutationErrors>{buyNFTMutation.error}</MutationErrors>

                  <Flex gap={0.25} justifyContent="center" alignItems="center">
                    <span style={{ fontSize: "10pt" }}>
                      100% of platform fees are used for staking rewards.
                    </span>
                    <div style={{ width: 25, height: 25 }}>
                      <Logo />
                    </div>
                  </Flex>
                </Stack>

                <Grid
                  gap={0.5}
                  gridTemplateColumns={"1fr auto"}
                  alignItems="center"
                >
                  <span>Need some KURO?</span>
                  <Button
                    as="a"
                    target="_blank"
                    href={BUY_KURO_LINK}
                    size="small"
                  >
                    Buy Kuro on Defi Kingdoms
                  </Button>
                </Grid>
              </Stack>
            </Grid>
          </Card>
        </StyledRow>
        <StyledRow gap={0.5}>
          <Card>
            <Grid
              gap={0.5}
              gridTemplateRows="auto 1fr auto"
              style={{ height: "100%" }}
            >
              <h2 style={{ margin: 0 }}>Details</h2>
              <Stack gap={0.25}>
                <Flex justifyContent="space-between">
                  <span>Owner</span>
                  <Anchor
                    href={`${HARMONY_EXPLORER_URL}/address/${listing.seller}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {abbreviateETHAddress(listing.seller)}
                  </Anchor>
                </Flex>
                <Flex justifyContent="space-between">
                  <span>Collection</span>
                  <Anchor
                    href={`${HARMONY_EXPLORER_URL}/address/${listing.nftContract}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {abbreviateETHAddress(listing.nftContract)}
                  </Anchor>
                </Flex>
                <Flex justifyContent="space-between">
                  <span>Full Image</span>
                  <Anchor href={nft.image} target="_blank" rel="noreferrer">
                    See full image
                  </Anchor>
                </Flex>
                <Flex justifyContent="space-between">
                  <span>Token ID</span>
                  <span>#{listing.tokenId}</span>
                </Flex>
                <Flex justifyContent="space-between">
                  <span>Total Created</span>
                  <span>{nftTotalSupplyQuery?.data || <Spinner />}</span>
                </Flex>
                <Flex justifyContent="space-between">
                  <span>Floor Price</span>
                  <span>{toSmallN(floorPrice, 9).toLocaleString()} KURO</span>
                </Flex>
                <Flex justifyContent="space-between">
                  <span>Market Transaction Fee</span>
                  <span>3%</span>
                </Flex>
              </Stack>
              <Button
                as="a"
                target="_blank"
                href={`${HARMONY_EXPLORER_URL}/address/${listing.nftContract}`}
              >
                View on Harmony Explorer
              </Button>
            </Grid>
          </Card>
          <Card>
            <Stack gap={0.5}>
              <h2 style={{ margin: 0 }}>Activity</h2>
              <TransactionHistory address={listing.nftContract} />
            </Stack>
          </Card>
        </StyledRow>
      </Stack>
    </Section>
  );
};

export { NFTDetail };

const StyledRow = styled(Grid)`
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: ${theme.breakpoints.xs}px) {
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
  }
`;

const Card = styled.div`
  background: white;
  padding: 1.5rem 2rem;
  border-radius: 1rem;

  @media (max-width: ${theme.breakpoints.xs}px) {
    padding: 1rem;
  }
`;
