import { useEffect, useState } from "react";
import detectEthereumProvider from "@metamask/detect-provider";
import useInterval from "../../utils/useInterval";
import { useSetRecoilState } from "recoil";
import {
  walletAuthorisedState,
  ethAddressState,
  isHarmonyNetworkConnected,
} from "./WalletState";

/*
 * Harmony documentation:
 *
 * Connect Wallet to Metamask
 * https://github.com/harmony-one/docs-home/blob/master/developers/wallets/metamask/interacting-with-metamask.md
 *
 */

const CHECK_WALLET_INTERVAL = 1500;

const HARMONY_CHAIN_IDS = [
  `1666600000`,
  `1666600001`,
  `1666600002`,
  `1666600003`,

  /* TESTNET */
  // `1666700000`,
  // `1666700001`,
  // `1666700002`,
  // `1666700003`,
];

const useConnectWallet = () => {
  const [provider, setProvider] = useState<any>();

  const setWalletAuthorisedState = useSetRecoilState(walletAuthorisedState);
  const setEthAddress = useSetRecoilState(ethAddressState);
  const setIsHarmonyNetworkConnected = useSetRecoilState(
    isHarmonyNetworkConnected
  );

  checkHarmonyNetwork();

  useEffect(() => {
    async function fetchProvider() {
      if (!setWalletAuthorisedState || !setIsHarmonyNetworkConnected) return;

      let res: any;
      res = await detectEthereumProvider();
      setProvider(res);

      // could be null if metamask not installed
      if (res) {
        res
          .request({ method: "eth_accounts" })
          .then(async (params: any) => {
            setIsHarmonyNetworkConnected(true);
            handleAccountsChanged(params);
          })
          .catch((err: any) => {
            setWalletAuthorisedState(false);
            if (err.code === 4001) {
              alert("Please connect to MetaMask.");
            } else {
              console.error(err);
            }
          });
      }
    }

    fetchProvider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setWalletAuthorisedState, setIsHarmonyNetworkConnected]);

  /*
   * This interval is a safecheck for users who switch networks.
   * React doesn't currently detect provider events,
   * or window.ethereum events.
   *
   * We will declaratively call checking the network,
   * and any other provider.on(EVENT, handler) events via interval.
   */
  useInterval(() => {
    checkHarmonyNetwork();

    provider?.removeAllListeners("accountsChanged");
    provider?.removeAllListeners("chainChanged");

    provider?.once("accountsChanged", (accounts: any) => {
      if (accounts.length >= 1) {
        setIsHarmonyNetworkConnected(true);
        setWalletAuthorisedState(true);
      } else {
        setIsHarmonyNetworkConnected(false);
        setWalletAuthorisedState(false);
      }
      window.location.reload();
    });

    provider?.on("chainChanged", (chainId: any) => {
      if (HARMONY_CHAIN_IDS.includes(chainId)) {
        setIsHarmonyNetworkConnected(true);
      } else {
        setIsHarmonyNetworkConnected(false);
      }
      window.location.reload();
    });
  }, CHECK_WALLET_INTERVAL);

  function checkHarmonyNetwork() {
    if (HARMONY_CHAIN_IDS.includes(provider?.networkVersion)) {
      setIsHarmonyNetworkConnected(true);
    } else {
      setIsHarmonyNetworkConnected(false);
    }
  }

  function handleAccountsChanged(accounts: any) {
    checkHarmonyNetwork();
    if (accounts.length === 0) {
      setIsHarmonyNetworkConnected(false);
    } else {
      setEthAddress(accounts[0]);
      setWalletAuthorisedState(true);
    }
  }

  async function connectWallet() {
    provider
      .request({ method: "eth_requestAccounts" })
      .then(async (params: any) => {
        handleAccountsChanged(params);
      })
      .catch((err: any) => {
        setWalletAuthorisedState(false);

        if (err.code === 4001) {
          alert("Please connect to MetaMask.");
        } else {
          console.error(err);
        }
      });
  }

  return { provider, connectWallet };
};

export { useConnectWallet };
