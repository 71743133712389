import { motion } from "framer-motion";
import React from "react";

const FadeIn = ({ ...props }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -4 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -4 }}
      transition={{ duration: 0.4, delay: 0.1, ease: "easeInOut" }}
      {...props}
    />
  );
};

export { FadeIn };
