import { AnimatePresence, motion } from "framer-motion";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Spinner } from "../components";
import { NFTCard } from "../features/marketplace";
import { useOnClickOutside } from "../utils";
import { ChevronDown, Close } from "./icons";

const { Tag } = NFTCard;

const MAX_TOKEN_LENGTH_RENDERED = 3;

interface OwnedTokensProps {
  tokens: number[];
  loading: boolean;
}

const OwnedTokens: React.FC<OwnedTokensProps> = ({ tokens, loading }) => {
  const ref = useRef<any>();
  const [isDropdownVisible, setDropdownVisible] = useState<boolean>(false);

  useOnClickOutside(ref, () => setDropdownVisible(false));

  const hasMoreTokensThanRendered = tokens.length > MAX_TOKEN_LENGTH_RENDERED;

  const firstTokens = tokens.filter((_, i) => i < MAX_TOKEN_LENGTH_RENDERED);

  const Content = () => {
    if (loading) return <Spinner />;

    return (
      <div style={{ marginTop: "0.5rem" }}>
        {firstTokens?.map((token, i) => (
          <React.Fragment key={token}>
            #{token}
            {i !== MAX_TOKEN_LENGTH_RENDERED - 1 && `, `}
          </React.Fragment>
        ))}{" "}
        {hasMoreTokensThanRendered && "..."}
      </div>
    );
  };

  if (!tokens || tokens.length === 0) return <Tag>Owned: 0</Tag>;
  if (!hasMoreTokensThanRendered) {
    return <Tag>Owned: {tokens.map((token) => `#${token}`).join(", ")}</Tag>;
  }

  return (
    <Tag {...{ ref }}>
      <AnimatePresence exitBeforeEnter>
        {isDropdownVisible ? (
          <Dropdown
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.15, delay: 0.15, ease: "easeInOut" }}
          >
            <DropdownHeader>
              <h4 style={{ margin: 0 }}>All Tokens</h4>
              <div
                style={{ width: 15, height: 15 }}
                onClick={() => setDropdownVisible(false)}
              >
                <Close />
              </div>
            </DropdownHeader>

            {tokens.map((token) => {
              return (
                <DropdownContent key={token}>
                  <span>#</span>
                  <span>{token}</span>
                </DropdownContent>
              );
            })}
          </Dropdown>
        ) : (
          <motion.div
            onClick={() => setDropdownVisible(true)}
            style={{
              cursor: hasMoreTokensThanRendered ? "pointer" : undefined,
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.15, delay: 0.15, ease: "easeInOut" }}
          >
            <DropdownHeader>
              <h4 style={{ margin: 0 }}>Owned</h4>
              <div
                style={{ width: 15, height: 15 }}
                onClick={() => setDropdownVisible(false)}
              >
                <ChevronDown />
              </div>
            </DropdownHeader>
            <DropdownContent>
              <Content />
            </DropdownContent>
          </motion.div>
        )}
      </AnimatePresence>
    </Tag>
  );
};

export { OwnedTokens };

const Dropdown = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;

  min-width: 150px;
  max-height: 300px;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;

  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  background: white;
`;

const DropdownHeader = styled.header`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  padding-bottom: 0.5rem;
  border-bottom: 1px solid #eee;
`;

const DropdownContent = styled.div`
  width: 100%;

  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
`;
