import { AnimatePresence } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { Button, FadeIn, H2, Section } from "../../../components";
import { LandingPageSectionProps } from "../../../routes";

const JoinTheClub: React.FC<LandingPageSectionProps> = ({ landingPage }) => {
  const {
    joinTheClubTitle,
    joinTheClubButtonText,
    joinTheClubLink,
    joinTheClubImage,
  } = landingPage;

  return (
    <AnimatePresence exitBeforeEnter>
      <Section>
        <FadeIn>
          <Wrapper>
            <H2 gradient style={{ textAlign: "center" }}>
              {joinTheClubTitle}
            </H2>
            <StyledImage src={joinTheClubImage.url} alt={joinTheClubTitle} />
            <Button
              as="a"
              href={joinTheClubLink}
              target="_blank"
              rel="noreferrer"
            >
              {joinTheClubButtonText}
            </Button>
          </Wrapper>
        </FadeIn>
      </Section>
    </AnimatePresence>
  );
};

export { JoinTheClub };

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

const StyledImage = styled.img`
  width: 24rem;
  max-width: 80%;
`;
