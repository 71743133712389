import React from "react";
import { useMediaQuery } from "react-responsive";
import { theme } from "../utils/theme";

interface ResponsiveProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  tiny?: React.ReactNode;
  xs?: React.ReactNode;
  sm?: React.ReactNode;
  md: React.ReactNode;
  lg?: React.ReactNode;
}

const Responsive: React.FC<ResponsiveProps> = ({
  tiny,
  xs,
  sm,
  md,
  lg,
  ...props
}) => {
  return (
    <>
      <Tiny {...props}>{tiny || xs || sm || md}</Tiny>
      <Xs {...props}>{xs || sm || md}</Xs>
      <Sm {...props}>{sm || md}</Sm>
      <Md {...props}>{md}</Md>
      <Lg {...props}>{lg || md}</Lg>
    </>
  );
};

export { Responsive };

/* for use on vertical phones */
const Tiny = ({ ...props }) => {
  const show = useMediaQuery({ maxWidth: theme.breakpoints.tiny });
  return show ? <div {...props} /> : null;
};

/* For use on larger phones and vertical tablets */
const Xs = ({ ...props }) => {
  const show = useMediaQuery({
    minWidth: theme.breakpoints.tiny + 1,
    maxWidth: theme.breakpoints.xs,
  });
  return show ? <div {...props} /> : null;
};

/* for use on tablets */
const Sm = ({ ...props }) => {
  const show = useMediaQuery({
    minWidth: theme.breakpoints.xs + 1,
    maxWidth: theme.breakpoints.sm,
  });
  return show ? <div {...props} /> : null;
};

/* for use on small desktops and laptops */
const Md = ({ ...props }) => {
  const show = useMediaQuery({
    minWidth: theme.breakpoints.sm + 1,
    maxWidth: theme.breakpoints.md,
  });
  return show ? <div {...props} /> : null;
};

/* for use on desktops */
const Lg = ({ ...props }) => {
  const show = useMediaQuery({ minWidth: theme.breakpoints.md + 1 });
  return show ? <div {...props} /> : null;
};
