import { AnimateSharedLayout } from "framer-motion";
import React from "react";
import { Error, Loading } from "../../components";
import { useApiQuery } from "../../features/api";
import { Listing } from "../../features/marketplace";
import { CardGrid } from "../../features/marketplace/CardGrid";
import { CollectionCard } from "../../features/marketplace/CollectionCard";
import { groupBy } from "../../utils";

const Collections = () => {
  const listingsQuery = useApiQuery("/listings/allForSale");

  const listings: Listing[] = listingsQuery.data;

  if (listingsQuery.error) return <Error>{listingsQuery.error}</Error>;
  if (listingsQuery.loading || !listingsQuery.data) return <Loading />;
  if (listings.length === 0)
    return <Error>There are no Collections for sale!</Error>;

  const collections = Object.entries(
    groupBy<string, Listing>(listings, "nftContract")
  );

  return (
    <CardGrid>
      <AnimateSharedLayout>
        {collections.map(([collectionAddress, collection]) => (
          <CollectionCard key={collectionAddress} {...{ collection }} />
        ))}
      </AnimateSharedLayout>
    </CardGrid>
  );
};

export { Collections };
