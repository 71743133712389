import styled from "styled-components";
import { theme } from "../utils/theme";

export const Tag = styled.div<{ isSelected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  height: fit-content;
  right: 0;
  top: 0;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  border-radius: 0.5rem;
  background: white;
  transition: ${theme.transition};
  ${({ isSelected }) => {
    if (isSelected)
      return {
        background: "black",
        color: "white",
      };
  }}
`;
