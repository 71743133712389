import React from "react";
import styled from "styled-components";
import { useFooters } from "../content";
import { Copyright, Socials } from "../navigation";

const Footer = () => {
  const { data } = useFooters();

  const footer = data?.footers[0];

  return (
    <StyledFooter>
      {footer && (
        <>
          <Copyright {...{ footer }} />
          <Socials {...{ footer }} />
        </>
      )}
    </StyledFooter>
  );
};

export { Footer };

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;

  background: black;
  color: white;

  padding: 3rem;

  text-align: center;
`;
