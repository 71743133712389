import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import NFTContract from "../compiled-contracts/marketplace/NFT.json";
import {
  Button,
  Error,
  FadeIn,
  H2,
  Input,
  Loading,
  OwnedTokens,
  Section,
  Select,
  SelectWrapper,
  Spinner,
} from "../components";
import { NFTv2, usePageIds, useTransferPage } from "../features/content";
import {
  useContractMutation,
  useContractQuery,
  useOwnedTokens,
} from "../features/contracts";
import {
  LoadingImage,
  NFTCard,
  NFTsSection,
  NFTTokenURI,
} from "../features/marketplace";
import { Layout, useAlert } from "../features/shared";
import {
  isHarmonyNetworkConnected,
  walletAuthorisedState,
} from "../features/wallet/WalletState";

const Airmail = () => {
  const { data, loading, error } = usePageIds();

  if (error)
    return (
      <Layout>
        <Error />
      </Layout>
    );
  if (loading || !data)
    return (
      <Layout>
        <Loading />
      </Layout>
    );

  const transferPageId = data.transferPageV2s[0].id;

  return (
    <Layout>
      <Helmet>
        <title>
          Kuro Shiba | Airmail - Transfer Stakeable NFTs Between Wallets!
        </title>
        <meta
          name="description"
          content="Transfer NFTs through the KURO dApp"
        />
      </Helmet>
      <Content id={transferPageId} />
    </Layout>
  );
};

const Content: React.FC<{ id: string }> = ({ id }) => {
  const { data, loading, error } = useTransferPage({ variables: { id } });

  if (error) return <Error />;
  if (loading || !data) return <Loading />;

  const { transferPageV2 } = data;

  return (
    <AnimatePresence exitBeforeEnter>
      <Section>
        <FadeIn>
          <Wrapper>
            <H2 as="h1" gradient style={{ textAlign: "center" }}>
              {transferPageV2.heading}
            </H2>

            <NFTsSection
              nfts={transferPageV2.nfts}
              content={({ data }) =>
                `stakeAddress` in data && <NFTContent {...{ data }} />
              }
            />
          </Wrapper>
        </FadeIn>
      </Section>
    </AnimatePresence>
  );
};

const { Card, ContentRow, Overlay } = NFTCard;

const NFTContent: React.FC<{ data: NFTv2 }> = ({ data }) => {
  const alert = useAlert();
  const walletAuthorised = useRecoilValue(walletAuthorisedState);
  const harmonyNetworkConnected = useRecoilValue(isHarmonyNetworkConnected);

  const balancOfBatchQuery = useOwnedTokens(data.nftAddress, 100);

  const [selectedID, setSelectedID] = useState<number>();
  const [address, setAddress] = useState<string>();

  const ownedTokensQuery = useContractQuery(
    NFTContract,
    data.nftAddress,
    async (contract, ethAddress) => {
      return await contract.methods
        .getTokensAvailableForTransfer(ethAddress)
        .call();
    }
  );

  const nftDataQuery = useContractQuery(
    NFTContract,
    data.nftAddress,
    async (contract) => {
      const url = await contract.methods.uri(0 /* Hardcoded */).call();
      const res = await fetch(url);

      if (res.ok) {
        return res.json();
      }
    }
  );

  const safeTransferFromMutation = useContractMutation(
    NFTContract,
    data.nftAddress,
    async (contract, ethAddress, { to, tokenID }) => {
      return await contract.methods
        .safeTransferFrom(ethAddress, to, tokenID, 1, [])
        .send({ from: ethAddress });
    },
    {
      onCompleted: async () => {
        await ownedTokensQuery.fetch();
        alert.open("Successful Transfer");
      },
    }
  );

  /* Variables */

  const legacyOwnedTokens = balancOfBatchQuery.data
    ?.map((tokenID: number, i: number) => (tokenID > 0 ? i : undefined))
    .filter(Boolean);

  const ownedTokens: number[] =
    legacyOwnedTokens?.length > 0
      ? legacyOwnedTokens
      : ownedTokensQuery.data?.map((i: string) => Number(i));

  const nft: NFTTokenURI = nftDataQuery.data;

  async function handleSubmit() {
    if (!selectedID) {
      alert.open("Please select Token ID!");
      return;
    } else if (!address) {
      alert.open("Please send to a valid address!");
      return;
    }

    await safeTransferFromMutation.mutate({ to: address, tokenID: selectedID });
  }

  const OverlayContent = () => {
    if (!harmonyNetworkConnected)
      return (
        <Overlay>
          <h3>Connect to Harmony</h3>
        </Overlay>
      );
    if (!walletAuthorised)
      return (
        <Overlay>
          <h3>Connect Wallet</h3>
        </Overlay>
      );
    if (!ownedTokens || ownedTokens.length === 0)
      return (
        <Overlay>
          <h3>You do not own {nft?.name}</h3>
        </Overlay>
      );
    return null;
  };

  const MutationErrors = () => {
    if (!safeTransferFromMutation.error) return null;
    return <ErrorWrapper>{safeTransferFromMutation.error}</ErrorWrapper>;
  };

  return (
    <Card
      image={
        <LoadingImage>
          <AnimatePresence>
            {nft ? (
              <motion.img
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.15 }}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                src={nft.preview}
                alt={nft.name}
              />
            ) : (
              <Spinner />
            )}
          </AnimatePresence>
        </LoadingImage>
      }
      header={
        ownedTokens && (
          <OwnedTokens
            loading={ownedTokensQuery.loading}
            tokens={ownedTokens}
          />
        )
      }
    >
      <h2
        style={{
          textAlign: "center",
          marginTop: "-0.5rem",
          fontSize: "16pt",
        }}
      >
        {nft?.name || <Spinner />}
      </h2>
      <StyledNFTContent>
        <OverlayContent />

        <ContentRow>
          <label hidden>Send to address</label>

          <InputWrapper>
            <span>Send to: </span>
            <Input
              title="Send to address"
              placeholder={"0x... address"}
              onChange={(e) => setAddress(e.target.value)}
            />
          </InputWrapper>
        </ContentRow>

        <StyledActions>
          <div>
            <label hidden>Token ID</label>
            <SelectWrapper>
              <Select
                defaultValue={""}
                onChange={(e) => setSelectedID(Number(e.target.value))}
                title="Token ID"
              >
                <option value="" disabled>
                  Select Token ID
                </option>

                {ownedTokens?.map((tokenID) => (
                  <option
                    key={tokenID}
                    value={tokenID}
                    selected={selectedID === tokenID}
                  >
                    #{tokenID}
                  </option>
                ))}
              </Select>
            </SelectWrapper>
          </div>

          <Button
            dark
            onClick={handleSubmit}
            loading={safeTransferFromMutation.loading}
            style={{ textTransform: "none" }}
          >
            Transfer
          </Button>
        </StyledActions>

        <MutationErrors />
      </StyledNFTContent>
    </Card>
  );
};

export { Airmail };

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
`;

const StyledNFTContent = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  margin-top: 0.5rem;
`;

const StyledActions = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.5rem;
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  align-items: center;
  gap: 0.5rem;
`;

const ErrorWrapper = styled.div`
  text-align: center;
  padding: 1rem;
  background: #ffc7c8;
  border-radius: 5px;
  margin-top: 0.5rem;
`;
