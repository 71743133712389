import React from "react";
import { H1, H3, Section } from "../components";
import { Layout } from "../features/shared";

const NoMatch = () => {
  return (
    <Layout>
      <Section>
        <H1>404</H1>
        <H3 style={{ textAlign: "center" }}>
          Sorry, but we could not find the page you were looking for.
        </H3>
      </Section>
    </Layout>
  );
};

export { NoMatch };
