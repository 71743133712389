import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import styled from "styled-components";
import { Flex } from "./Flex";

enum Direction {
  LEFT = "left",
  RIGHT = "right",
  TOP = "top",
  BOTTOM = "bottom",
}

interface TooltipProps {
  direction?: Direction;
  tooltip?: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({
  tooltip,
  children,
  direction = Direction.RIGHT,
}) => {
  const [isVisible, setVisible] = useState<boolean>(false);

  return (
    <Wrapper
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <AnimatePresence>
        {isVisible && (
          <StyledTooltip
            {...{ direction }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.15, ease: "easeInOut" }}
          >
            {tooltip}
          </StyledTooltip>
        )}
      </AnimatePresence>
      {children}
    </Wrapper>
  );
};

export { Tooltip };

const Wrapper = styled(Flex)`
  position: relative;

  align-items: center;
  justify-content: center;
`;

const StyledTooltip = styled(Flex)<{
  direction?: Direction;
}>`
  position: absolute;

  align-items: center;
  justify-content: center;
  width: max-content;

  margin: 1rem;
  padding: 0.5rem 0.75rem;

  border-radius: 0.25rem;
  background: black;
  color: white;

  &:before {
    content: "";
    border-style: solid;
    position: absolute;

    ${({ direction }) => {
      switch (direction) {
        case Direction.LEFT:
          return {
            left: "100%",
            borderWidth: "7px 0 7px 7px",
            borderColor: "transparent transparent transparent black",
          };
        case Direction.RIGHT:
          return {
            right: "100%",
            borderWidth: "7px 7px 7px 0",
            borderColor: "transparent black transparent transparent",
          };
        case Direction.TOP:
          return {
            top: "100%",
            borderWidth: "7px 7px 0 7px",
            borderColor: "black transparent transparent transparent",
          };
        case Direction.BOTTOM:
          return {
            bottom: "100%",
            borderWidth: "0 7px 7px 7px",
            borderColor: "transparent transparent black transparent",
          };
        default:
          return;
      }
    }};
  }

  ${({ direction }) => {
    switch (direction) {
      case Direction.LEFT:
        return { right: "100%" };
      case Direction.RIGHT:
        return { left: "100%" };
      case Direction.TOP:
        return { bottom: "100%" };
      case Direction.BOTTOM:
        return { top: "100%" };
      default:
        return;
    }
  }};
`;
