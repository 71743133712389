import { AnimatePresence } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { FadeIn, H2, H3, Section } from "../../../components";
import { LandingPageSectionProps } from "../../../routes";

const Roadmap: React.FC<LandingPageSectionProps> = ({ landingPage }) => {
  const { roadmapTitle, roadmapCards } = landingPage;

  return (
    <AnimatePresence exitBeforeEnter>
      <Section>
        <FadeIn>
          <Wrapper>
            <H2 gradient>{roadmapTitle}</H2>

            <Features>
              {roadmapCards.map((item) => {
                return (
                  <Feature key={item.id}>
                    <H3 shadow>{item.title}</H3>
                    <ul>
                      {item.description.map((listItem) => (
                        <li key={listItem}>{listItem}</li>
                      ))}
                    </ul>
                  </Feature>
                );
              })}
            </Features>
          </Wrapper>
        </FadeIn>
      </Section>
    </AnimatePresence>
  );
};

export { Roadmap };

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
`;

const Features = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
`;

const Feature = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 16rem;

  & > ul {
    list-style: none;
    padding: 0;
  }
`;
