import React from "react";
import styled from "styled-components";
import { useConnectWallet } from ".";
import { Button } from "../../components";
import { useRecoilValue } from "recoil";
import {
  walletAuthorisedState,
  ethAddressState,
  isHarmonyNetworkConnected,
} from "./WalletState";

const Wallet = () => {
  const { connectWallet } = useConnectWallet();

  const walletAuthorised = useRecoilValue(walletAuthorisedState);
  const ethAddress = useRecoilValue(ethAddressState);
  const harmonyNetworkConnected = useRecoilValue(isHarmonyNetworkConnected);

  return (
    <StyledWallet>
      <Button onClick={connectWallet}>
        {walletAuthorised ? (
          harmonyNetworkConnected ? (
            <StyledAddress>
              {String(ethAddress).substring(0, 6) +
                "..." +
                String(ethAddress).substring(38)}
            </StyledAddress>
          ) : (
            "Connect to Harmony"
          )
        ) : (
          "Connect Wallet"
        )}
      </Button>
    </StyledWallet>
  );
};

export { Wallet };

const StyledWallet = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const StyledAddress = styled.div`
  width: 13rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
