import { AnimatePresence, AnimateSharedLayout } from "framer-motion";
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import NFTContract from "../../compiled-contracts/marketplace/NFT.json";
import {
  Button,
  Error,
  Flex,
  Grid,
  Input,
  Loading,
  Responsive,
  Select,
  SelectWrapper,
  Stack,
} from "../../components";
import { Close } from "../../components/icons";
import { useApiQuery } from "../../features/api";
import { useContractQuery } from "../../features/contracts";
import { Listing, ListingCard, NFTTokenURI } from "../../features/marketplace";
import { CardGrid } from "../../features/marketplace/CardGrid";
import { Logo } from "../../features/shared";
import { ethAddressState } from "../../features/wallet/WalletState";
import {
  BUY_KURO_LINK,
  groupBy,
  matchAddress,
  useOnClickOutside,
} from "../../utils";
import { theme } from "../../utils/theme";

type Sort = {
  method: "" | "RECENTLY_LISTED" | "PRICE_ASC" | "PRICE_DES";
  label: string;
};

const SORTS: Sort[] = [
  { method: "RECENTLY_LISTED", label: "Most Recent" },
  { method: "PRICE_ASC", label: "Price: Low to High" },
  { method: "PRICE_DES", label: "Price: High to Low" },
];

const Market = () => {
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const collectionAddress = searchParams.get("collection");

  return <Content {...{ collectionAddress }} />;
};

const Content: React.FC<{
  collectionAddress: string | null;
}> = ({ collectionAddress }) => {
  const navigate = useNavigate();

  const ref = useRef<any>();
  const [showFilters, setShowFilters] = useState<boolean>(false);

  useOnClickOutside(ref, () => setShowFilters(false));

  const listingsQuery = useApiQuery("/listings/allForSale");
  const ethAddress = useRecoilValue(ethAddressState);

  const [searchInput, setSearchInput] = useState<string>("");
  const [sortMethod, setSortMethod] = useState<Sort["method"]>(SORTS[0].method);

  const listings: Listing[] = listingsQuery.data;

  if (listingsQuery.error) return <Error>{listingsQuery.error}</Error>;
  if (listingsQuery.loading || !listingsQuery.data) return <Loading />;
  if (listings.length === 0) return <Error>No NFTs are for sale!</Error>;

  const collections = Object.entries(
    groupBy<string, Listing>(listings, "nftContract")
  );

  const listingsFilteredByCollection = collectionAddress
    ? listings.filter((listing) => listing.nftContract === collectionAddress)
    : listings;

  const listingsFilteredBySearch = searchInput
    ? listingsFilteredByCollection.filter((listing) => {
        return JSON.stringify(listing)
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      })
    : listingsFilteredByCollection;

  const listingsSorted = listingsFilteredBySearch.sort((a, b) =>
    sortListings(a, b, sortMethod)
  );

  const filters = (
    <AnimatePresence>
      <FiltersButton
        as="button"
        pressed={showFilters}
        onClick={() => {
          setShowFilters(!showFilters);
        }}
      >
        Filters{collectionAddress && ": On"}
      </FiltersButton>

      {showFilters && (
        <FiltersWrapper
          gap={0.5}
          {...{ ref }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.15 }}
        >
          <Grid gridTemplateColumns={"1fr auto"}>
            <h4 style={{ margin: 0 }}>Filters</h4>
            <div
              style={{ width: 15, height: 15 }}
              onClick={() => setShowFilters(false)}
            >
              <Close />
            </div>
          </Grid>
          <SelectWrapper
            // @ts-ignore
            dark={collectionAddress ? true : false}
            style={{
              borderRadius: "0.25rem",
            }}
          >
            <Select
              // @ts-ignore
              size="small"
              value={collectionAddress || ""}
              defaultValue={""}
              onChange={(e) => {
                e.preventDefault();

                if (e.target.value === "" || e.target.value === "ALL") {
                  navigate("/night-market/market");
                  return;
                } else {
                  navigate(`/night-market/market?collection=${e.target.value}`);
                }
              }}
              title="Sort by Collection"
            >
              <option value="" disabled>
                Collection
              </option>

              <option value="ALL">All Collections</option>

              {collections.map(([address, collection]) => (
                <CollectionOption
                  key={address}
                  {...{ collection }}
                  selected={address === collectionAddress}
                />
              ))}
            </Select>
          </SelectWrapper>
          <SelectWrapper
            style={{
              borderRadius: "0.25rem",
            }}
          >
            <Select
              // @ts-ignore
              size="small"
              onChange={(e) => {
                e.preventDefault();

                if (e.target.value === "") {
                  setSortMethod("");
                  return;
                } else {
                  setSortMethod(e.target.value as Sort["method"]);
                }
              }}
              defaultValue={""}
              title="Sort By Price"
            >
              <option value="" disabled>
                Price
              </option>

              {SORTS.map((sort) => (
                <option
                  key={sort.method}
                  value={sort.method}
                  selected={sort.method === sortMethod}
                >
                  {sort.label}
                </option>
              ))}
            </Select>
          </SelectWrapper>
          <Button
            size="small"
            disabled={!collectionAddress}
            onClick={() => {
              setSortMethod("");
              navigate("/night-market/market");
            }}
          >
            Reset Filters
          </Button>
        </FiltersWrapper>
      )}
    </AnimatePresence>
  );

  return (
    <Stack gap={1}>
      <Responsive
        xs={
          <Stack style={{ position: "relative" }} gap={0.5}>
            {filters}
          </Stack>
        }
        md={
          <Grid gridTemplateColumns="1fr auto" gap={2}>
            <Flex alignItems="center" gap={0.5}>
              <Flex alignItems="center" gap={0.25}>
                <div style={{ width: 30, height: 30 }}>
                  <Logo />
                </div>
                <span>100% of KURO fees are used for staking rewards.</span>
              </Flex>

              <Button as="a" target="_blank" href={BUY_KURO_LINK} size="small">
                BUY KURO
              </Button>
            </Flex>

            <Flex
              alignItems="center"
              gap={0.5}
              style={{ position: "relative" }}
            >
              <Input
                style={{ background: "white", minWidth: 200 }}
                placeholder="Search by 0x... Address"
                value={searchInput}
                onChange={(e) => {
                  e.preventDefault();
                  setSearchInput(e.target.value);
                }}
              />
              {filters}
            </Flex>
          </Grid>
        }
      />
      <CardGrid>
        <AnimateSharedLayout>
          {listingsSorted.map((listing) => (
            <ListingCard
              key={`${listing._id}`}
              {...{ listing }}
              owned={
                (ethAddress && matchAddress(listing.seller, ethAddress)) ||
                false
              }
            />
          ))}
        </AnimateSharedLayout>
      </CardGrid>
    </Stack>
  );
};

export { Market };

const CollectionOption: React.FC<{
  collection: Listing[];
  selected?: boolean;
}> = ({ collection, selected }) => {
  const nftDataQuery = useContractQuery(
    NFTContract,
    collection[0].nftContract,
    async (contract) => {
      const tokenID = Number(collection[0].tokenId);

      const url = await contract.methods.uri(tokenID).call();
      const res = await fetch(url);

      if (res.ok) {
        return res.json();
      }
    }
  );

  const nft: NFTTokenURI = nftDataQuery.data;

  if (!nft) return null;

  return (
    <option value={collection[0].nftContract} {...{ selected }}>
      {nft.name}
    </option>
  );
};

const FiltersWrapper = styled(Stack)`
  z-index: 1;
  position: absolute;

  top: calc(100% + 0.5rem);
  right: 0.5rem;

  background: white;

  padding: 0.5rem;
  border-radius: 0.5rem;

  @media (max-width: ${theme.breakpoints.sm}px) {
    position: relative;
    top: 0;
    left: 0;
    max-width: 100vw;
    right: auto;
  }
`;

const FiltersButton = styled(Button)<{ pressed?: boolean }>`
  ${({ pressed }) => {
    if (pressed) return { background: "black", color: "white" };
  }}

  text-transform: capitalize;

  padding: 0.5rem 1rem;

  border-radius: 0.25rem;
  border: 2px solid black;
`;

function sortListings(a: Listing, b: Listing, sortMethod: Sort["method"]) {
  switch (sortMethod) {
    case "RECENTLY_LISTED": {
      return -1;
    }
    case "PRICE_ASC": {
      return Number(a.price) - Number(b.price);
    }
    case "PRICE_DES": {
      return Number(b.price) - Number(a.price);
    }
    default: {
      return -1;
    }
  }
}
