import React from "react";
import styled from "styled-components";
import { Footer } from "../content";
import { Logo } from "../shared";

const Copyright: React.FC<{ footer: Footer }> = ({ footer }) => {
  return (
    <StyledCopyright>
      <div style={{ width: 50 }}>
        <Logo />
      </div>
      <StyledText>{footer.copyrightText}</StyledText>
    </StyledCopyright>
  );
};

export { Copyright };

const StyledCopyright = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledText = styled.span`
  background-image: repeating-linear-gradient(
    45deg,
    #ffd782,
    #ffa6a8 25%,
    #bf83ff 50%,
    #8ab8ff 75%,
    #b3ff81
  );

  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;
