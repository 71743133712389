import styled from "styled-components";
import { theme } from "../utils/theme";

const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 8rem;

  margin-bottom: 16rem;

  & > * {
    max-width: ${theme?.maxPageWidth}px;
  }

  @media (max-width: ${theme.breakpoints.xs}px) {
    margin: 2rem;
  }

  @media (max-width: ${theme.breakpoints.tiny}px) {
    margin: 1rem;
  }

  @media (max-width: ${theme.breakpoints.sm}px) {
    margin-bottom: 8rem;
  }
`;

export { Section };
