import React, { useState } from "react";
import { Asset } from ".";
import NFTContract from "../../compiled-contracts/marketplace/NFT.json";
import { Button, Input, MutationErrors } from "../../components";
import { Close } from "../../components/icons";
import { useContractMutation } from "../contracts";
import { Modal } from "../shared";

interface ListNFTModalProps {
  asset: Asset;
  closeModal: () => void;
  onCompleted: () => Promise<void>;
}

const ListNFTModal: React.FC<ListNFTModalProps> = ({
  asset,
  closeModal,
  onCompleted,
}) => {
  const [inputPrice, setInputPrice] = useState<number>(1);

  /* Mutations */

  const listNFTMutation = useContractMutation(
    NFTContract,
    asset.address,
    async (contract, ethAddress) => {
      return await contract.methods
        .sellNFT(Number(asset.tokenID), Number(inputPrice) * Math.pow(10, 9))
        .send({ from: ethAddress });
    },
    { onCompleted }
  );

  async function handleListNFT() {
    await listNFTMutation.mutate({});
  }

  return (
    <Modal.Content>
      <Modal.Header>
        <strong>Set your Price</strong>
        <div style={{ width: 20, cursor: "pointer" }} onClick={closeModal}>
          <Close />
        </div>
      </Modal.Header>

      <>
        <Input
          type="number"
          value={inputPrice}
          onChange={(e) => {
            e.preventDefault();
            setInputPrice(Number(e.target.value));
          }}
        />

        <Button
          dark
          loading={listNFTMutation.loading}
          disabled={listNFTMutation.loading}
          onClick={handleListNFT}
        >
          List
        </Button>

        <MutationErrors>{listNFTMutation.error}</MutationErrors>
      </>
    </Modal.Content>
  );
};

export { ListNFTModal };
