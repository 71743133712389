import styled from "styled-components";
import { theme } from "../utils/theme";

const H3 = styled.h3<{ shadow?: boolean }>`
  font-size: 2.25rem;
  line-height: 1;

  margin: 0;

  ${({ shadow }) => {
    return (
      shadow && {
        fontFamily: "'Fredoka One'",
        color: "#ccfff9",
        textShadow: "-4px 4px 0 #a868ff",
      }
    );
  }}

  @media (max-width: ${theme.breakpoints.sm}px) {
    font-size: 2rem;
  }

  @media (max-width: ${theme.breakpoints.xs}px) {
    font-size: 1.75rem;
  }
`;

export { H3 };
