import { useCallback, useEffect, useState } from "react";
import { HARMONY_RPC_URL } from "../../utils";

interface Props {
  variables: {
    address: string;
    pageIndex?: number;
    pageSize?: number;
    fullTx?: boolean;
  };
}

const useTransactionHistory = ({ variables }: Props) => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const fetchTransactionHistory = useCallback(async () => {
    setLoading(true);

    try {
      const res = await fetch(HARMONY_RPC_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: 1,
          jsonrpc: "2.0",
          method: "hmyv2_getTransactionsHistory",
          params: [
            {
              address: variables.address,
              pageIndex: variables.pageIndex || 0,
              pageSize: variables.pageSize || 10,
              fullTx: variables.fullTx || true,
              order: "DESC",
            },
          ],
        }),
      });

      if (res.ok) {
        setData(await res.json());
        setLoading(false);
      } else {
        setError("Contract error");
        setLoading(false);
      }
    } catch (error: Error | any) {
      console.log(error);
      setLoading(false);
      if ("message" in error) setError("Network Error: " + error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchTransactionHistory();
  }, [fetchTransactionHistory]);

  return { data, fetch: fetchTransactionHistory, loading, error };
};

export { useTransactionHistory };
