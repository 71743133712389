export function formatCurrency(amount?: number, currency?: string) {
  if (!amount) return "";

  if (!currency) return `$${parseFloat(amount.toFixed(5))}`;

  const formattedPrice = new Intl.NumberFormat("en-UK", {
    style: "currency",
    currency: currency,
    minimumSignificantDigits: 1,
    maximumSignificantDigits: 4,
  }).format(amount);

  return formattedPrice;
}
