import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { H2, Section, Button } from "../components";
import { Layout } from "../features/shared";
import { formatCurrency } from "../utils";
import { createChart, ISeriesApi, IChartApi } from "lightweight-charts";
import { Helmet } from "react-helmet";

let oneCandlestickSeries: ISeriesApi<"Candlestick">;
let usdcCandlestickSeries: ISeriesApi<"Candlestick">;
let tenMinutes = 15 * 60 * 1000;
let usdcChart: IChartApi;
let oneChart: IChartApi;
let chartRange: number = 15;
let getCurrentPricesInterval: NodeJS.Timeout;
let updateChartPricesInterval: NodeJS.Timeout;

const Chart = () => {
  let [selectedChartRange, setselectedChartRange] = useState(0);
  let [usdcPriceNow, setPriceNow] = useState(0);
  let [usdcPercentageChangeOver24Hours, setPercentageChangeOver24Hours] =
    useState(0);
  let [usdcHighOver24Hours, setHighOver24Hours] = useState(0);
  let [usdcLowOver24Hours, setLowOver24Hours] = useState(0);

  let [onePriceNow, setOnePriceNow] = useState(0);
  let [onePercentageChangeOver24Hours, setOnePercentageChangeOver24Hours] =
    useState(0);
  let [oneHighOver24Hours, setOneHighOver24Hours] = useState(0);
  let [oneLowOver24Hours, setOneLowOver24Hours] = useState(0);

  let oneElementRef = useRef<HTMLDivElement>(null);
  let usdcElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setselectedChartRange(chartRange);
    getChartData(chartRange);
    getCurrentPrices();
    setPriceAndChartDataIntervals(chartRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setPriceAndChartDataIntervals(chartRange: number) {
    clearInterval(updateChartPricesInterval);
    clearInterval(getCurrentPricesInterval);
    updateChartPricesInterval = setInterval(() => updateChart(chartRange), tenMinutes);
    getCurrentPricesInterval = setInterval(() => getCurrentPrices(), 10000);
  }

  function getChartData(chartRange: number) {
    fetch(
      "https://kuro.chartsgraphs.xyz/api/chartPrices/" +
        chartRange + "/allChartData"
    )
      .then((response) => response.json())
      .then((response) => {
        
        var width = usdcElementRef.current?.clientWidth
        var height = usdcElementRef.current?.clientHeight

        usdcChart = createChart("chartKURO", {
          width: width,
          height: height,
          timeScale: {
            timeVisible: true,
            secondsVisible: false,
          },
        });
      
        usdcCandlestickSeries = usdcChart.addCandlestickSeries({
          priceFormat: {
            type: "price",
            precision: 7,
            minMove: 0.000001,
          },
        });

        usdcCandlestickSeries.setData(response.usdc);

        usdcChart.applyOptions({
          layout: {
            backgroundColor: "transparent",
            textColor: "#696969",
          },
        });  

        var width = oneElementRef.current?.clientWidth
        var height = oneElementRef.current?.clientHeight

        oneChart = createChart("chartONE", {
          width: width,
          height: height,
          timeScale: {
            timeVisible: true,
            secondsVisible: false,
          },
        });

        oneCandlestickSeries = oneChart.addCandlestickSeries({
          priceFormat: {
            type: "price",
            precision: 7,
            minMove: 0.000001,
          },
        });

        oneCandlestickSeries.setData(response.one);

        oneChart.applyOptions({
          layout: {
            backgroundColor: "transparent",
            textColor: "#696969",
          },
        });  
      });
      
    setselectedChartRange(chartRange);
  }

  function updateChart(chartRange: number) {
    fetch(
      "https://kuro.chartsgraphs.xyz/api/chartPrices/" +
        chartRange + "/allChartData"
    )
      .then((response) => response.json())
      .then((response) => {
        usdcCandlestickSeries.setData(response.usdc);
        oneCandlestickSeries.setData(response.one);
      });
  
    setselectedChartRange(chartRange);
    setPriceAndChartDataIntervals(chartRange);
  }

  function getCurrentPrices() {
    fetch("https://kuro.chartsgraphs.xyz/api/currentPrice/allCurrentPrices")
      .then((response) => response.json())
      .then((response) => {

        setOnePriceNow(response.one.now);
        setOnePercentageChangeOver24Hours(
          response.one.previous24hours.percentageChange
        );
        setOneHighOver24Hours(response.one.high24hours);
        setOneLowOver24Hours(response.one.low24hours);

        setPriceNow(response.usdc.now);
        setPercentageChangeOver24Hours(
          response.usdc.previous24hours.percentageChange
        );
        setHighOver24Hours(response.usdc.high24hours);
        setLowOver24Hours(response.usdc.low24hours);
        document.title = "KURO $" + response.usdc.now;
      });
  }

  return (
    <Layout>
      <Helmet>
        <meta name="description" content="KURO Charts" />
      </Helmet>
      <Section>
        <ChartWrapper>
          <ContentHolder>
            <H2 as="h1" gradient style={{ textAlign: "center" }}>
              KURO/USDC
            </H2>
            <CurrentPriceContent>
              <CurrentPrice>
                <strong>KURO/USDC</strong>
                <P>${usdcPriceNow}</P>
              </CurrentPrice>
              <CurrentPrice>
                <strong>24 hour change</strong>
                <P>{usdcPercentageChangeOver24Hours}%</P>
              </CurrentPrice>
              <CurrentPrice>
                <strong>24 hour high</strong>
                <P>${usdcHighOver24Hours}</P>
              </CurrentPrice>
              <CurrentPrice>
                <strong>24 hour low</strong>
                <P>${usdcLowOver24Hours}</P>
              </CurrentPrice>
            </CurrentPriceContent>
            <ChartContainer id="chartKURO" ref={usdcElementRef}></ChartContainer>
          </ContentHolder>

          <ContentHolder>
            <H2 as="h1" gradient style={{ textAlign: "center" }}>
              KURO/ONE
            </H2>
            <CurrentPriceContent>
              <CurrentPrice>
                <strong>KURO/ONE</strong>
                <P>{onePriceNow}</P>
              </CurrentPrice>
              <CurrentPrice>
                <strong>24 hour change</strong>
                <P>{onePercentageChangeOver24Hours}%</P>
              </CurrentPrice>
              <CurrentPrice>
                <strong>24 hour high</strong>
                <P>{oneHighOver24Hours}</P>
              </CurrentPrice>
              <CurrentPrice>
                <strong>24 hour low</strong>
                <P>{oneLowOver24Hours}</P>
              </CurrentPrice>
            </CurrentPriceContent>
            <ChartContainer id="chartONE" ref={oneElementRef}></ChartContainer>
          </ContentHolder>
        </ChartWrapper>

        <CurrentPriceContent>
          <Button
            dark={selectedChartRange === 15}
            onClick={() => {
              updateChart(15);
            }}
          >
            15
          </Button>
          <Button
            dark={selectedChartRange === 30}
            onClick={() => {
              updateChart(30);
            }}
          >
            30
          </Button>
          <Button
            dark={selectedChartRange === 60}
            onClick={() => {
              updateChart(60);
            }}
          >
            Hour
          </Button>
          <Button
            dark={selectedChartRange === 1440}
            onClick={() => {
              updateChart(1440);
            }}
          >
            Day
          </Button>
        </CurrentPriceContent>
      </Section>
    </Layout>
  );
};

export { Chart };

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2.5rem;
  margin-bottom: 10px;
  width: 100%;
`;

const ContentHolder = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
`;

const P = styled.p`
  margin: 10px 0 3px 0;
`;

const CurrentPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CurrentPriceContent = styled.div`
  display: flex;
  flex-wrap: row;
  align-items: center;
  justify-content: center;
  gap: 2rem 3rem;
  margin-top: 15px;
`;

const ChartContainer = styled.div`
  min-height: 380px;
  width:100%;
  border-radius: 15px;
  padding: 2px;
  background: #fff;
`;
