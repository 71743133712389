import { AnimatePresence } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, FadeIn, H3, Section } from "../../../components";
import { LandingPageSectionProps } from "../../../routes";
import { theme } from "../../../utils/theme";

const Summary: React.FC<LandingPageSectionProps> = ({ landingPage }) => {
  const navigate = useNavigate();

  const { summaryTitle, summaryCards } = landingPage;

  return (
    <AnimatePresence exitBeforeEnter>
      <Section>
        <FadeIn>
          <Wrapper>
            <H3 style={{ textAlign: "center" }}>{summaryTitle}</H3>

            <Features>
              {summaryCards.map((item) => {
                return (
                  <Feature key={item.id}>
                    <img src={item.image.url} alt={item.title} />
                    <H3 shadow>{item.title}</H3>
                    <p>{item.description}</p>
                    <Button onClick={() => navigate(item.buttonPath)}>
                      {item.buttonText}
                    </Button>
                  </Feature>
                );
              })}
            </Features>
          </Wrapper>
        </FadeIn>
      </Section>
    </AnimatePresence>
  );
};

export { Summary };

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
`;

const Features = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  gap: 3rem;
`;

const Feature = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
  width: 25rem;

  @media (max-width: ${theme.breakpoints.xs}px) {
    width: 100%;
  }

  & > img {
    width: 12rem;
  }
`;
