import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import Web3 from "web3";
import NFTContract from "../contracts/NFT.json";
import { useConnectWallet } from "../wallet";
import { ethAddressState } from "../wallet/WalletState";
import { initContract } from "./initContract";

/* Query */

const useOwnedTokens = (NFT_CONTRACT_ADDRESS: string, BATCH_LENGTH: number) => {
  const { provider } = useConnectWallet();

  const ethAddress = useRecoilValue(ethAddressState);

  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  if (!ethAddress) console.log("You are not signed in");

  const fetchOwnedTokens = useCallback(async () => {
    setLoading(true);

    const web3 = new Web3(provider);

    const contract = await initContract(
      web3,
      NFTContract,
      NFT_CONTRACT_ADDRESS
    );

    try {
      const arrayFromBatch = [...Array(BATCH_LENGTH).keys()];

      const res = await contract.methods
        .balanceOfBatch(
          arrayFromBatch.map((_) => ethAddress),
          arrayFromBatch
        )
        .call();

      if (res) {
        setData(res);
        setLoading(false);
      } else {
        setError("Contract error");
        setLoading(false);
      }
    } catch (error: Error | any) {
      console.log(error);
      setLoading(false);
      if ("message" in error) setError("Network Error: " + error.message);
    }
  }, [NFT_CONTRACT_ADDRESS, BATCH_LENGTH, ethAddress, provider]);

  useEffect(() => {
    ethAddress && provider && fetchOwnedTokens();
  }, [ethAddress, fetchOwnedTokens, provider]);

  return { fetchOwnedTokens, data, loading, error };
};

export { useOwnedTokens };
