import { atom } from "recoil";

type Modal =
  | {
      type:
        | "LIST_NFT"
        | "EXHIBITIONS_STAKE"
        | "EXHIBITIONS_UNSTAKE"
        | "MUSEUM_STAKE"
        | "MUSEUM_UNSTAKE";
      children?: (closeModal: () => void) => React.ReactNode;
    }
  | undefined;

export const modalState = atom<Modal>({
  key: "modalState",
  default: undefined,
});
