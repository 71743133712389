import { useState } from "react";
import { useRecoilValue } from "recoil";
import Web3 from "web3";
import { useConnectWallet } from "../wallet";
import { ethAddressState } from "../wallet/WalletState";
import { initContract } from "./initContract";
import { PayableTransactionResponse } from ".";

/* Mutation */

interface useMutationOptions {
  onCompleted?: (() => void) | (() => Promise<void>);
}

const useContractMutation = (
  CONTRACT_JSON: any,
  ADDRESS: string,
  request: (contract: any, ethAddress: string, variables: any) => Promise<any>,
  { onCompleted }: useMutationOptions
) => {
  const { provider } = useConnectWallet();

  const ethAddress = useRecoilValue(ethAddressState);

  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const web3 = new Web3(provider);

  async function mutate(variables: any) {
    if (!ethAddress) {
      console.log("You are not signed in");
      return;
    }

    setLoading(true);

    const contract = await initContract(web3, CONTRACT_JSON, ADDRESS);

    try {
      const res: PayableTransactionResponse = await request(
        contract,
        ethAddress,
        variables
      );

      if (res.status) {
        setData(res);
        setLoading(false);
        setError(undefined);

        onCompleted?.();
      } else {
        setError("Contract error");
        setLoading(false);
      }
    } catch (error: Error | any) {
      if ("message" in error) {
        setError("Network Error: " + error.message);
        return;
      }
      setError("Network Error: " + JSON.stringify(error));
    }
  }

  return { mutate, data, loading, error };
};

export { useContractMutation };

/**        

    console.log(error);
    setLoading(false);

    const message = error.message.includes(`"message"`)
      ? JSON.parse(error.message).message
      : error.message;

    if ("message" in error) {
      setError("Network Error: " + message);
    }
    setError(message);

*/
