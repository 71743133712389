import { fromBech32 } from "@harmony-js/crypto";
import dayjs from "dayjs";
import React from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import Web3 from "web3";
import NFTContract from "../../compiled-contracts/marketplace/NFT.json";
import {
  Anchor,
  Button,
  Error,
  Flex,
  Grid,
  Responsive,
  Spinner,
  Stack,
} from "../../components";
import { useContractQuery } from "../../features/contracts";
import { NFTTokenURI, useTransactionHistory } from "../../features/marketplace";
import { useConnectWallet } from "../../features/wallet";
import { ethAddressState } from "../../features/wallet/WalletState";
import {
  abbreviateETHAddress,
  findAddressInString,
  HARMONY_EXPLORER_URL,
  MARKETPLACE_CONTRACT_ADDRESS,
  matchAddress,
  uniqueBy,
} from "../../utils";
import { theme } from "../../utils/theme";

const Activity = () => {
  const ethAddress = useRecoilValue(ethAddressState);

  if (!ethAddress) return <Error>Please connect your Wallet!</Error>;

  return <Content {...{ ethAddress }} />;
};

export { Activity };

const Content: React.FC<{ ethAddress: string }> = ({ ethAddress }) => {
  const { provider } = useConnectWallet();

  const transactionHistoryQuery = useTransactionHistory({
    variables: { address: ethAddress },
  });

  const NFTsBoughtQuery = useTransactionHistory({
    variables: { address: MARKETPLACE_CONTRACT_ADDRESS },
  });

  if (transactionHistoryQuery.error || NFTsBoughtQuery.error)
    return (
      <Error>{transactionHistoryQuery.error || NFTsBoughtQuery.error}</Error>
    );
  if (transactionHistoryQuery.loading || NFTsBoughtQuery.loading)
    return <Spinner />;
  if (!transactionHistoryQuery.data || !NFTsBoughtQuery.data) return <Error />;

  const transactionHistory = transactionHistoryQuery.data.result.transactions;
  const NFTsBought = NFTsBoughtQuery.data.result.transactions;

  const transactions = uniqueBy(
    [...transactionHistory, ...NFTsBought],
    "ethHash"
  ).sort((a: any, b: any) => b.timestamp - a.timestamp);

  const web3 = new Web3(provider);

  const marketplaceTransactions = transactions.filter((transaction: any) => {
    const fourByteHex = transaction.input.substring(0, 9);
    const event = parseEventSignatures(fourByteHex);

    const parameters =
      event &&
      Object.values(
        web3.eth.abi.decodeParameters(
          event.arguments,
          transaction.input.substring(10)
        )
      );

    const isPurchaseNFTEvent =
      event?.method === CONTRACT_METHOD_SIGNATURES["0xdaab0b9"].method;

    const purchasedByYou =
      isPurchaseNFTEvent &&
      matchAddress(ethAddress, fromBech32(transaction.from));

    const isExternalPurchaseNFTEvent =
      isPurchaseNFTEvent &&
      !findAddressInString(parameters[2], ethAddress) &&
      !purchasedByYou;

    const isGeneralMarketplaceEvent =
      !isPurchaseNFTEvent &&
      matchAddress(MARKETPLACE_CONTRACT_ADDRESS, fromBech32(transaction.to)) &&
      !purchasedByYou;

    if (isExternalPurchaseNFTEvent) return null;
    if (isGeneralMarketplaceEvent) return null;

    return event !== null;
  });

  if (marketplaceTransactions.length === 0)
    return <Error>You have no activity!</Error>;

  return (
    <Wrapper gap={2}>
      <Stack gap={2}>
        {marketplaceTransactions.map((transaction: any) => {
          return (
            <TransactionEvent key={transaction.hash} {...{ transaction }} />
          );
        })}
      </Stack>
      <Button
        as="a"
        target="_blank"
        href={`${HARMONY_EXPLORER_URL}/address/${ethAddress}`}
      >
        View on Harmony Explorer
      </Button>
    </Wrapper>
  );
};

const TransactionEvent: React.FC<{ transaction: any }> = ({ transaction }) => {
  const ethAddress = useRecoilValue(ethAddressState);
  const fourByteHex = transaction.input.substring(0, 9);
  const event = parseEventSignatures(fourByteHex);

  const { provider } = useConnectWallet();

  const web3 = new Web3(provider);

  const parameters =
    event &&
    Object.values(
      web3.eth.abi.decodeParameters(
        event.arguments,
        transaction.input.substring(10)
      )
    );

  const timeStamp = dayjs.unix(transaction.timestamp).fromNow();

  const isPurchasedByYou = matchAddress(
    ethAddress,
    fromBech32(transaction.from)
  );

  const label =
    event.method === "purchaseNFT(address,uint256,string)" && isPurchasedByYou
      ? "You bought this NFT"
      : event.label;

  if (!ethAddress) return null;

  return (
    <Grid gap={0.25}>
      <Grid gridTemplateColumns="1fr auto">
        <Flex gap={0.25} alignItems="flex-end">
          <span>{label}</span>
          <Responsive
            xs={<div />}
            md={
              <code style={{ fontSize: "9pt" }}>
                <Anchor
                  target="_blank"
                  href={`${HARMONY_EXPLORER_URL}/tx/${transaction.ethHash}`}
                >
                  {abbreviateETHAddress(transaction.ethHash, 61)}
                </Anchor>
              </code>
            }
          />
        </Flex>
        <span style={{ color: "#ddd" }}>{timeStamp}</span>
      </Grid>

      {event.method === CONTRACT_METHOD_SIGNATURES["0xdaab0b9"].method && (
        <TransactionPreview address={parameters[0]} tokenID={parameters[1]} />
      )}
      {event.method === CONTRACT_METHOD_SIGNATURES["0x91a48a1"].method && (
        <TransactionPreview
          address={fromBech32(transaction.to)}
          tokenID={parameters[0]}
        />
      )}
      {event.method === CONTRACT_METHOD_SIGNATURES["0x730889a"].method && (
        <TransactionPreview address={parameters[0]} tokenID={parameters[1]} />
      )}
      {event.method === CONTRACT_METHOD_SIGNATURES["0x6a62784"].method && (
        <TransactionPreview address={parameters[0]} />
      )}
    </Grid>
  );
};

const TransactionPreview: React.FC<{
  address: string;
  tokenID?: string;
}> = ({ address, tokenID }) => {
  const nftDataQuery = useContractQuery(
    NFTContract,
    address,
    async (contract) => {
      const url = await contract.methods.uri(Number(tokenID || 0)).call();
      const res = await fetch(url);

      if (res.ok) {
        return res.json();
      }
    }
  );

  if (nftDataQuery.loading) return <Spinner />;
  if (!nftDataQuery.data) return null;

  const nft: NFTTokenURI = nftDataQuery.data;

  return (
    <Grid gridTemplateColumns="auto 1fr" gap={0.25} alignItems="center">
      <img
        src={nft.preview}
        alt={nft.name}
        style={{ height: 40, borderRadius: "100%" }}
      />
      <Stack gap={0.15}>
        <span>
          {nft?.name} {tokenID && `#${tokenID}`}
        </span>
        <code style={{ fontSize: "9pt" }}>
          <Anchor
            target="_blank"
            href={`${HARMONY_EXPLORER_URL}/address/${address}`}
            rel="noreferrer"
          >
            {abbreviateETHAddress(address)}
          </Anchor>
        </code>
      </Stack>
    </Grid>
  );
};

function parseEventSignatures(fourByteHex: string[]) {
  // @ts-ignore
  if (!CONTRACT_METHOD_SIGNATURES[fourByteHex]) return null;
  // @ts-ignore
  return CONTRACT_METHOD_SIGNATURES[fourByteHex];
}

const CONTRACT_METHOD_SIGNATURES = {
  "0xdaab0b9": {
    method: "purchaseNFT(address,uint256,string)",
    label: "Your NFT was purchased",
    arguments: ["address", "uint256", "string"],
    argumentsOutput: ["NFT Address", "Token ID", "Seller"],
  },
  "0x91a48a1": {
    method: "sellNFT(uint256,uint256)",
    label: "Listed for Sale",
    arguments: ["uint256", "uint256"],
    argumentsOutput: ["Token ID", "Price"],
  },
  "0x6a62784": {
    method: "mint(address)",
    label: "Claimed Mint",
    arguments: ["address"],
    argumentsOutput: ["NFT Address"],
  },
  "0x730889a": {
    method: "cancelNFTSale(address,uint256)",
    label: "Unlisted",
    arguments: ["address", "uint256"],
    argumentsOutput: ["NFT Address", "Token ID"],
  },
};

const Wrapper = styled(Grid)`
  position: relative;

  background: white;
  border-radius: 1rem;

  padding: 1.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 600px;
  width: ${theme.maxPageWidth}px;

  @media (max-width: ${theme.breakpoints.md}px) {
    width: 100%;
  }

  @media (max-width: ${theme.breakpoints.sm}px) {
    padding: 0.75rem;
    max-height: 100%;
    overflow-y: hidden;
    width: calc(100vw - 2rem);
  }

  box-sizing: border-box;
  background-clip: padding-box; /* !importanté */
  border: solid 5px transparent;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -5px; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: white;
  }

  &:hover {
    &:before {
      background-image: linear-gradient(
        45deg,
        #ffe6b2,
        #ffc7c8 25%,
        #debfff 50%,
        #c8deff 75%,
        #e1ffcd
      );
    }
  }
`;
