import { gql, QueryHookOptions, useQuery } from "@apollo/client";

type PageIdsData = {
  landingPages: Array<{ id: string }>;
  mintPages: Array<{ id: string }>;
  artMuseumPageV2s: Array<{ id: string }>;
  exhibitionsPages: Array<{ id: string }>;
  fishPondsPages: Array<{ id: string }>;
  nightMarketPages: Array<{ id: string }>;
  transferPageV2s: Array<{ id: string }>;
};

type PageIdsVariables = {
  id: string;
};

export const usePageIds = (
  options?: QueryHookOptions<PageIdsData, PageIdsVariables>
) => {
  return useQuery<PageIdsData, PageIdsVariables>(PAGE_IDS, options);
};

export const PAGE_IDS = gql`
  query pageIds {
    landingPages {
      id
    }
    mintPages {
      id
    }
    artMuseumPageV2s {
      id
    }
    exhibitionsPages {
      id
    }
    fishPondsPages {
      id
    }
    nightMarketPages {
      id
    }
    transferPageV2s {
      id
    }
  }
`;
