import styled from "styled-components";
import { theme } from "../utils/theme";

const H2 = styled.h2<{ gradient?: boolean; darkGradient?: boolean }>`
  font-size: 3rem;
  line-height: 1.2;

  margin: 0;

  ${({ gradient }) => {
    return (
      gradient && {
        fontFamily: "'Fredoka One'",
        backgroundImage:
          "linear-gradient(45deg, #ffd782, #ffa6a8 25%, #bf83ff 50%, #8ab8ff 75%, #b3ff81)",
        textShadow: "4px -4px 0 #fff",

        WebkitBackgroundClip: "text",
        backgroundClip: "text",
        WebkitTextFillColor: "transparent",
      }
    );
  }}

  ${({ darkGradient }) => {
    return (
      darkGradient && {
        fontFamily: "'Fredoka One'",
        backgroundImage:
          "linear-gradient(45deg, #ffd782, #ffa6a8 25%, #bf83ff 50%, #8ab8ff 75%, #b3ff81)",

        WebkitBackgroundClip: "text",
        backgroundClip: "text",
        WebkitTextFillColor: "transparent",

        fontWeight: "normal",
      }
    );
  }}

  @media (max-width: ${theme.breakpoints.xs}px) {
    font-size: 2.5rem;
  }
`;

export { H2 };
