import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import Web3 from "web3";
import { useConnectWallet } from "../wallet";
import { ethAddressState } from "../wallet/WalletState";
import { initContract } from "./initContract";

/* Query */

const useContractQuery = (
  CONTRACT_JSON: any,
  CONTRACT_ADDRESS: string,
  request: (contract: any, ethAddress?: string) => Promise<any>
) => {
  const ethAddress = useRecoilValue(ethAddressState);

  const { provider } = useConnectWallet();

  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const fetch = useCallback(async () => {
    setLoading(true);

    const web3 = new Web3(provider);

    const contract = await initContract(web3, CONTRACT_JSON, CONTRACT_ADDRESS);

    try {
      const res = await request(contract, ethAddress);

      if (res) {
        setData(res);
        setLoading(false);
      } else {
        setError("Contract error");
        setLoading(false);
      }
    } catch (error: Error | any) {
      console.log(error);
      setLoading(false);
      if ("message" in error) setError("Network Error: " + error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, ethAddress]);

  useEffect(() => {
    provider && fetch();
  }, [fetch, provider, ethAddress]);

  return { fetch, data, loading, error };
};

export { useContractQuery };
