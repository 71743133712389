import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

interface GridProps extends React.HTMLProps<HTMLDivElement> {
  gap?: number;
}

const Grid = styled(motion.div)<
  GridProps &
    Pick<
      React.CSSProperties,
      | "justifyContent"
      | "alignItems"
      | "gridTemplateColumns"
      | "gridTemplateRows"
    >
>`
  display: grid;

  gap: ${({ gap }) => (gap ? `${gap}rem` : undefined)};

  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};

  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  grid-template-rows: ${({ gridTemplateRows }) => gridTemplateRows};

  /* Gap Polyfill */

  column-gap: ${({ gap }) => (gap ? `${gap}rem` : undefined)};
  row-gap: ${({ gap }) => (gap ? `${gap}rem` : undefined)};
  margin: ${({ gap }) => (gap ? `calc(${gap}rem / -2)` : undefined)};

  & > * {
    margin: ${({ gap }) => (gap ? `calc(${gap}rem / 2)` : undefined)};
  }
`;

export { Grid };
