import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { NightMarketPage } from "./types";

type NightMarketPageData = {
  nightMarketPage: NightMarketPage;
};

type NightMarketPageVariables = {
  id: string;
};

export const useNightMarketPage = (
  options?: QueryHookOptions<NightMarketPageData, NightMarketPageVariables>
) => {
  return useQuery<NightMarketPageData, NightMarketPageVariables>(
    NIGHT_MARKET_PAGE,
    options
  );
};

export const NIGHT_MARKET_PAGE = gql`
  query nightMarketPage($id: ID!) {
    nightMarketPage(where: { id: $id }) {
      id
      heading
      title
      description
      image {
        url
      }
    }
  }
`;
