import { motion } from "framer-motion";
import React from "react";

const Fade = ({ ...props }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.15, delay: 0.15, ease: "easeInOut" }}
      {...props}
    />
  );
};

export { Fade };
