import { useState, useEffect } from "react";

const usePrice = () => {
  const [price, setPrice] =
    useState<{ now: number; marketCap: number; circulatingSupply: number }>();

  useEffect(() => {
    async function fetchPrice() {
      const res = await fetch(
        "https://kuro.chartsgraphs.xyz/api/currentPrice"
      );
      const json = await res.json();
      setPrice(json.price);
    }

    fetchPrice();
  }, []);

  return { price };
};

export { usePrice };
