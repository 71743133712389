import React from "react";
import { useRecoilValue } from "recoil";
import { Anchor, Error, Loading } from "../../components";
import { useApiQuery } from "../../features/api";
import { useContractQuery } from "../../features/contracts";
import NFTContract from "../../compiled-contracts/marketplace/NFT.json";
import {
  Asset,
  AssetCard,
  Listing,
  MyListingCard,
} from "../../features/marketplace";
import { CardGrid } from "../../features/marketplace/CardGrid";
import { ethAddressState } from "../../features/wallet/WalletState";
import { matchAddress } from "../../utils";
import { Link } from "react-router-dom";

const Assets = () => {
  const ethAddress = useRecoilValue(ethAddressState);

  if (!ethAddress) return <Error>Please connect your Wallet!</Error>;

  return <Content {...{ ethAddress }} />;
};

export { Assets };

const Content: React.FC<{ ethAddress: string }> = ({ ethAddress }) => {
  const listingsQuery = useApiQuery("/listings/allForSale");
  const assetsQuery = useApiQuery(`/nftOwners/allIDs/${ethAddress}`);

  if (assetsQuery.error || listingsQuery.error)
    return <Error>{assetsQuery.error || listingsQuery.error}</Error>;
  if (
    assetsQuery.loading ||
    !assetsQuery.data ||
    listingsQuery.loading ||
    !listingsQuery.data
  )
    return <Loading />;

  const ownedNFTs: Asset[] = assetsQuery.data.contract
    ?.map(({ tokenIds, ...contract }: any) => {
      return tokenIds?.map((token: string) => ({
        ...contract,
        tokenID: token,
      }));
    })
    ?.flat();

  const myListings: Listing[] = listingsQuery.data.filter((listing: Listing) =>
    matchAddress(listing.seller, ethAddress)
  );

  if (myListings.length === 0 && ownedNFTs.length === 0) {
    return (
      <Error>
        <div style={{ textAlign: "center" }}>
          <span>You have no NFTs eligible for resale.</span>
          <br />
          <span style={{ fontSize: "9pt" }}>
            Go to{" "}
            <Anchor as={Link} to="/museum" style={{ color: "white" }}>
              Art Museum{" "}
            </Anchor>{" "}
            to see your staked NFTs.
          </span>
        </div>
      </Error>
    );
  }

  return (
    <CardGrid>
      {myListings?.map((listing) => {
        return (
          <MyListingCard /* unlistNFT() */
            key={listing.nftContract}
            {...{ listing }}
            refetch={async () => {
              await listingsQuery.fetch();
              await assetsQuery.fetch();
            }}
          />
        );
      })}
      {ownedNFTs?.map((asset) => {
        return (
          <AssetWrapper
            key={`${asset.address}-${asset.tokenID}`}
            {...{ asset }}
            refetch={async () => {
              await assetsQuery.fetch();
              await listingsQuery.fetch();
            }}
          />
        );
      })}
    </CardGrid>
  );
};

const AssetWrapper: React.FC<{
  asset: Asset;
  refetch: () => Promise<void>;
}> = ({ asset, refetch }) => {
  const ownedTokensQuery = useContractQuery(
    NFTContract,
    asset.address,
    async (contract, ethAddress) => {
      return await contract.methods
        .getTokensAvailableForTransfer(ethAddress)
        .call();
    }
  );

  if (ownedTokensQuery.error) return <Error>{ownedTokensQuery.error}</Error>;
  if (ownedTokensQuery.loading) return <Loading />;
  if (!ownedTokensQuery.data) return <Error />;

  const owned = ownedTokensQuery.data
    ?.map((i: string) => Number(i))
    ?.includes(Number(asset.tokenID));

  if (!owned) return null;

  return <AssetCard {...{ asset, refetch }} />;
};
