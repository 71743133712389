import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useRecoilValue, useSetRecoilState } from "recoil";
import NFTContract from "../compiled-contracts/marketplace/NFT.json";
import NFTStakeContract from "../compiled-contracts/marketplace/NFTStake.json";
import LegacyNFTStakeContract from "../compiled-contracts/legacy/NFTStake.json";
import {
  Button,
  Error,
  FadeIn,
  Flex,
  H2,
  Loading,
  Markdown,
  MutationErrors,
  OwnedTokens,
  Section,
  Spinner,
  Stack,
} from "../components";
import { useArtMuseumPage, usePageIds } from "../features/content";
import { useContractMutation, useContractQuery } from "../features/contracts";
import {
  LoadingImage,
  NFTCard,
  NFTsSection,
  NFTTokenURI,
  SectionSwitch,
} from "../features/marketplace";
import { StakeModal, UnstakeModal } from "../features/museum";
import { Layout, modalState, useAlert } from "../features/shared";
import {
  isHarmonyNetworkConnected,
  walletAuthorisedState,
} from "../features/wallet/WalletState";
import { toSmallN, unique } from "../utils";
import useInterval from "../utils/useInterval";

const ArtMuseum = () => {
  const { data, loading, error } = usePageIds();

  if (error)
    return (
      <Layout>
        <Error />
      </Layout>
    );
  if (loading || !data)
    return (
      <Layout>
        <Loading />
      </Layout>
    );

  const artMuseumPageId = data.artMuseumPageV2s[0].id;

  return (
    <Layout>
      <Helmet>
        <title>
          Kuro Shiba | Art Museum - Stake Adorable NFTs to Earn KURO!
        </title>
        <meta
          name="description"
          content="Stake your Kuro Shiba NFTs in the Art Museum to start earning rewards in KURO, our revenue-generating governance token. The longer you stake your NFTs, the more KURO you'll earn! 🍑"
        />
      </Helmet>
      <Content id={artMuseumPageId} />
    </Layout>
  );
};

export { ArtMuseum };

const Content: React.FC<{ id: string }> = ({ id }) => {
  const { data, loading, error } = useArtMuseumPage({ variables: { id } });

  const [currentSectionTitle, setCurrentSectionTitle] = useState<
    "ACTIVE" | "INACTIVE"
  >("ACTIVE");

  if (error) return <Error>{error.message}</Error>;
  if (loading || !data) return <Loading />;

  const { artMuseumPageV2 } = data;

  return (
    <AnimatePresence exitBeforeEnter>
      <Section>
        <FadeIn>
          <Stack gap={2} alignItems="center" style={{ paddingBottom: "8rem" }}>
            <H2 as="h1" gradient style={{ textAlign: "center" }}>
              {artMuseumPageV2.heading}
            </H2>

            <Flex>
              <SectionSwitch
                active={currentSectionTitle === "ACTIVE"}
                onClick={() => setCurrentSectionTitle("ACTIVE")}
                leftBorderRadius
              >
                Active
              </SectionSwitch>
              <SectionSwitch
                active={currentSectionTitle === "INACTIVE"}
                onClick={() => setCurrentSectionTitle("INACTIVE")}
                rightBorderRadius
              >
                Inactive
              </SectionSwitch>
            </Flex>

            {currentSectionTitle === "ACTIVE" && (
              <NFTsSection
                nfts={artMuseumPageV2.activeNfts}
                content={({ data }) =>
                  `stakeAddress` in data && (
                    <NFTContent
                      nftAddress={data.nftAddress}
                      stakeAddress={data.stakeAddress}
                      active={true}
                    />
                  )
                }
              />
            )}

            {currentSectionTitle === "INACTIVE" && (
              <NFTsSection
                nfts={artMuseumPageV2.inactiveNfts}
                content={({ data }) =>
                  `stakeAddress` in data && (
                    <NFTContent
                      nftAddress={data.nftAddress}
                      stakeAddress={data.stakeAddress}
                      active={false}
                    />
                  )
                }
              />
            )}
          </Stack>
        </FadeIn>
      </Section>
    </AnimatePresence>
  );
};

const { Card, Actions, Buttons, ContentRow, Overlay } = NFTCard;

const NFTContent: React.FC<{
  nftAddress: string;
  stakeAddress: string;
  active: boolean;
}> = ({ nftAddress, active, ...props }) => {
  const alert = useAlert();

  const walletAuthorised = useRecoilValue(walletAuthorisedState);
  const harmonyNetworkConnected = useRecoilValue(isHarmonyNetworkConnected);
  const setModal = useSetRecoilState(modalState);

  /* Queries */

  const ownedTokensQuery = useContractQuery(
    NFTContract,
    nftAddress,
    async (contract, ethAddress) => {
      return await contract.methods
        .getTokensAvailableForTransfer(ethAddress)
        .call();
    }
  );

  const nftDataQuery = useContractQuery(
    NFTContract,
    nftAddress,
    async (contract) => {
      const url = await contract.methods.uri(0 /* Hardcoded */).call();
      const res = await fetch(url);

      if (res.ok) {
        return res.json();
      }
    }
  );

  const stakeAddressQuery = useContractQuery(
    NFTContract,
    nftAddress,
    async (contract) => {
      return await contract.methods.stakingContract().call();
    }
  );

  const stakeAddress = stakeAddressQuery.data || props.stakeAddress;

  const stakedTokensQuery = useContractQuery(
    NFTStakeContract,
    stakeAddress,
    async (contract, ethAddress) => {
      return await contract.methods.getAllNFTsUserStaked(ethAddress).call();
    }
  );

  const legacyStakedTokenQuery = useContractQuery(
    LegacyNFTStakeContract,
    stakeAddress,
    async (contract, ethAddress) => {
      return await contract.methods.ownerToken(ethAddress).call();
    }
  );

  const tokensPerBlockQuery = useContractQuery(
    NFTStakeContract,
    stakeAddress,
    async (contract) => {
      return await contract.methods.tokensPerBlock().call();
    }
  );

  const stakeContractBalanceQuery = useContractQuery(
    NFTStakeContract,
    stakeAddress,
    async (contract) => {
      return await contract.methods.getStakeContractBalance().call();
    }
  );

  const totalNFTsStakedQuery = useContractQuery(
    NFTStakeContract,
    stakeAddress,
    async (contract) => {
      return await contract.methods.totalNFTsStaked().call();
    }
  );

  const pendingRewardsQuery = useContractQuery(
    NFTStakeContract,
    stakeAddress,
    async (contract, ethAddress) => {
      return await contract.methods.getPendingRewards(ethAddress).call();
    }
  );

  /* Mutations */

  const withdrawRewardsMutation = useContractMutation(
    NFTStakeContract,
    stakeAddress,
    async (contract, ethAddress) => {
      return await contract.methods
        .withdrawRewardsNoArray()
        .send({ from: ethAddress });
    },
    {
      onCompleted: async () => {
        await stakedTokensQuery.fetch();
        await pendingRewardsQuery.fetch();
        await stakeContractBalanceQuery.fetch();

        alert.open("Rewards Withdrawn");
      },
    }
  );

  const stakeMultipleNFTsMutation = useContractMutation(
    NFTContract,
    nftAddress,
    async (contract, ethAddress, { tokenIDs }) => {
      return await contract.methods
        .stakeMultipleNFTs(tokenIDs)
        .send({ from: ethAddress });
    },
    {
      onCompleted: async () => {
        await ownedTokensQuery.fetch();
        await stakedTokensQuery.fetch();
        await pendingRewardsQuery.fetch();
        await stakeContractBalanceQuery.fetch();
        await totalNFTsStakedQuery.fetch();

        alert.open("Successfully Staked");
      },
    }
  );

  const unstakeMultipleNFTsMutation = useContractMutation(
    NFTStakeContract,
    stakeAddress,
    async (contract, ethAddress, { tokenIDs }) => {
      return await contract.methods
        .unstakeMultipleNFTs(tokenIDs)
        .send({ from: ethAddress });
    },
    {
      onCompleted: async () => {
        await ownedTokensQuery.fetch();
        await stakedTokensQuery.fetch();
        await pendingRewardsQuery.fetch();
        await stakeContractBalanceQuery.fetch();
        await totalNFTsStakedQuery.fetch();

        alert.open("Successfully Unstaked");
      },
    }
  );

  const legacyUnstakeNFTMutation = useContractMutation(
    LegacyNFTStakeContract,
    stakeAddress,
    async (contract, ethAddress, { tokenID }) => {
      return await contract.methods
        .unstakeNFT(tokenID)
        .send({ from: ethAddress });
    },
    {
      onCompleted: async () => {
        await ownedTokensQuery.fetch();
        await stakedTokensQuery.fetch();
        await pendingRewardsQuery.fetch();
        await stakeContractBalanceQuery.fetch();
        await totalNFTsStakedQuery.fetch();

        alert.open("Successfully Unstaked");
      },
    }
  );

  /* Variables */

  const nft: NFTTokenURI = nftDataQuery.data;

  const tokensPerBlock = toSmallN(Number(tokensPerBlockQuery.data), 9);
  const getStakeContractBalance = Number(stakeContractBalanceQuery.data);
  const totalNFTsStaked = Number(totalNFTsStakedQuery.data);
  const pendingRewards = toSmallN(Number(pendingRewardsQuery.data), 9);

  const timeLeft =
    toSmallN(getStakeContractBalance, 9) /
    (((totalNFTsStaked * tokensPerBlock) / 2.3) * 86400);

  const ownedTokens: number[] = ownedTokensQuery.data?.map((i: string) =>
    Number(i)
  );

  const stakedTokens: number[] | undefined = stakedTokensQuery.data
    ?.map((tokenID: string) => {
      return Number(tokenID);
    })
    .sort((a: number, b: number) => a - b);

  const ownedAndStakedTokens = unique(
    [ownedTokens, stakedTokens]
      .flat()
      .filter((tokenID) => typeof tokenID === `number`)
  ).sort((a: number, b: number) => a - b) as number[];

  /* Effects */

  useInterval(async () => {
    await pendingRewardsQuery.fetch();
  }, 20000);

  /* Helper Functions */

  async function handleWithdrawRewards() {
    if (!stakedTokens) return;

    const tokenID = stakedTokens[0];
    await withdrawRewardsMutation.mutate({ tokenID });
  }

  function openStakeModal() {
    if (!ownedTokens) return;

    if (ownedTokens.length === 1) {
      stakeMultipleNFTsMutation.mutate({
        tokenIDs: ownedTokens,
      });
      return;
    }

    setModal({
      type: "MUSEUM_STAKE",
      children: (closeModal) => (
        <StakeModal
          {...{
            nftAddress,
            closeModal,
            onCompleted: async () => {
              await ownedTokensQuery.fetch();
              await stakedTokensQuery.fetch();
              await pendingRewardsQuery.fetch();
              await stakeContractBalanceQuery.fetch();
              await totalNFTsStakedQuery.fetch();
            },
          }}
        />
      ),
    });
  }

  function openUnstakeModal() {
    if (!stakedTokens) return;

    if (stakedTokens.length === 1) {
      unstakeMultipleNFTsMutation.mutate({
        tokenIDs: stakedTokens,
      });
      return;
    }

    setModal({
      type: "MUSEUM_UNSTAKE",
      children: (closeModal) => (
        <UnstakeModal
          {...{
            stakeAddress,
            closeModal,
            onCompleted: async () => {
              await ownedTokensQuery.fetch();
              await stakedTokensQuery.fetch();
              await pendingRewardsQuery.fetch();
              await stakeContractBalanceQuery.fetch();
              await totalNFTsStakedQuery.fetch();
            },
          }}
        />
      ),
    });
  }

  function handleLegacyUnstakeNFT() {
    if (!stakedTokens) return;

    legacyUnstakeNFTMutation.mutate({
      tokenID: Number(legacyStakedTokenQuery.data),
    });
  }

  const OverlayContent = () => {
    if (!harmonyNetworkConnected)
      return (
        <Overlay>
          <h3>Connect to Harmony</h3>
        </Overlay>
      );
    if (!walletAuthorised)
      return (
        <Overlay>
          <h3>Connect Wallet</h3>
        </Overlay>
      );
    if (ownedAndStakedTokens.length === 0)
      return (
        <Overlay>
          <h3>You do not own {nft?.name}</h3>
        </Overlay>
      );
    return null;
  };

  return (
    <Card
      image={
        <LoadingImage>
          <AnimatePresence>
            {nft ? (
              <motion.img
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.15 }}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                src={nft.preview}
                alt={nft.name}
              />
            ) : (
              <Spinner />
            )}
          </AnimatePresence>
        </LoadingImage>
      }
      header={
        ownedAndStakedTokens && (
          <OwnedTokens
            loading={ownedTokensQuery.loading || stakedTokensQuery.loading}
            tokens={ownedAndStakedTokens}
          />
        )
      }
    >
      <div>
        <div style={{ textAlign: "center", marginBottom: "1rem" }}>
          <h2
            style={{
              textAlign: "center",
              marginTop: "-0.5rem",
              marginBottom: "0.5rem",
              fontSize: "16pt",
            }}
          >
            {nft?.name || <Spinner />}
          </h2>
          <Markdown>{nft?.description}</Markdown>
        </div>

        <>
          {tokensPerBlock ? (
            <ContentRow>
              <span>Rewards</span>
              <span>
                {tokensPerBlock.toPrecision(4).toLocaleString()} per block
              </span>
            </ContentRow>
          ) : null}
          <ContentRow>
            <span>Time Left</span>
            <span>
              {timeLeft > 1
                ? `~${timeLeft.toPrecision(4).toLocaleString()} days`
                : "This staking has finished"}
            </span>
          </ContentRow>
          {active ? (
            <ContentRow>
              <span>Staked Tokens</span>
              <span>
                {stakedTokens?.length
                  ? `${stakedTokens.map((tokenID) => `#${tokenID}`).join(", ")}`
                  : `None staked`}
              </span>
            </ContentRow>
          ) : (
            <ContentRow>
              <span>Staked Tokens</span>
              <span>
                {legacyStakedTokenQuery.data !== "0"
                  ? `#${legacyStakedTokenQuery.data}`
                  : `None staked`}
              </span>
            </ContentRow>
          )}
        </>
      </div>

      {active ? (
        <Actions>
          <OverlayContent />
          <Buttons>
            <Button
              onClick={openStakeModal}
              loading={stakeMultipleNFTsMutation.loading}
              disabled={
                ownedTokens?.length === 0 || stakeMultipleNFTsMutation.loading
              }
              loadingText="Staking"
            >
              Stake
            </Button>
            <Button
              onClick={openUnstakeModal}
              loading={unstakeMultipleNFTsMutation.loading}
              disabled={
                stakedTokens?.length === 0 ||
                unstakeMultipleNFTsMutation.loading
              }
              loadingText="Unstaking"
            >
              Unstake
            </Button>
          </Buttons>
          <Button
            dark
            onClick={handleWithdrawRewards}
            loading={withdrawRewardsMutation.loading}
            disabled={
              stakedTokens?.length === 0 ||
              withdrawRewardsMutation.loading ||
              !pendingRewards
            }
          >
            Claim {pendingRewards ? pendingRewards.toLocaleString() : null} Kuro
          </Button>
          <MutationErrors>
            {stakeMultipleNFTsMutation.error ||
              unstakeMultipleNFTsMutation.error ||
              withdrawRewardsMutation.error}
          </MutationErrors>
        </Actions>
      ) : (
        <Actions>
          <Button
            onClick={handleLegacyUnstakeNFT}
            loading={legacyUnstakeNFTMutation.loading}
            disabled={
              legacyStakedTokenQuery.data === "0" ||
              legacyUnstakeNFTMutation.loading
            }
            loadingText="Unstaking"
          >
            Unstake
          </Button>

          <MutationErrors>{legacyUnstakeNFTMutation.error}</MutationErrors>
        </Actions>
      )}
    </Card>
  );
};
