import React from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";
import { Footer } from "../content";
import { Link } from "react-router-dom";

const Socials: React.FC<{ footer: Footer }> = ({ footer }) => {
  return (
    <StyledSocials>
      {footer.socials.map((social) => (
        <Social key={social.name} {...{ social }} />
      ))}
    </StyledSocials>
  );
};

export { Socials };

const Social: React.FC<{
  social: {
    name: string;
    link: string;
    internalLink: boolean;
    icon: { url: string };
  };
}> = ({ social }) => {
  if (social.internalLink) {
    return (
      <StyledSocial as={Link} to={social.link}>
        <img
          src={social.icon.url}
          alt={social.name}
          style={{ width: "100%" }}
        />
      </StyledSocial>
    );
  }

  return (
    <StyledSocial
      target="_blank"
      rel="noreferrer"
      href={social.link}
      title={social.name}
    >
      <img src={social.icon.url} alt={social.name} style={{ width: "100%" }} />
    </StyledSocial>
  );
};

const StyledSocials = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;

  @media (max-width: ${theme.breakpoints.xs}px) {
    flex-wrap: wrap;
    gap: 1rem;
  }
`;

const StyledSocial = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 1rem;

  @media (max-width: ${theme.breakpoints.xs}px) {
    width: 1.5rem;
  }

  @media (max-width: ${theme.breakpoints.tiny}px) {
    width: 1.25rem;
  }

  &:hover {
    transform: scale(1.3, 1.3);
  }
`;
