import { fromBech32 } from "@harmony-js/crypto";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";
import Web3 from "web3";
import {
  Anchor,
  Flex,
  Grid,
  Responsive,
  Spinner,
  Stack,
} from "../../components";
import {
  abbreviateETHAddress,
  HARMONY_EXPLORER_URL,
  toSmallN,
} from "../../utils";
import { useTransactionHistory } from "../marketplace";
import { useConnectWallet } from "../wallet";

dayjs.extend(relativeTime);

interface Props {
  address: string;
}

const TransactionHistory: React.FC<Props> = ({ address }) => {
  const transactionHistoryQuery = useTransactionHistory({
    variables: { address },
  });

  if (!transactionHistoryQuery.data) return <Spinner />;

  const transactions = transactionHistoryQuery.data.result.transactions;

  return <>{transactions && <Content {...{ transactions }} />}</>;
};

export { TransactionHistory };

const Content: React.FC<{ transactions: any[] }> = ({ transactions }) => {
  return (
    <Stack gap={1} style={{ maxHeight: 300, overflowY: "auto" }}>
      {transactions.map((transaction) => {
        return <TransactionEvent key={transaction.hash} {...{ transaction }} />;
      })}
    </Stack>
  );
};

const TransactionEvent: React.FC<{ transaction: any }> = ({ transaction }) => {
  const fourByteHex = transaction.input.substring(0, 9);

  const { provider } = useConnectWallet();
  const web3 = new Web3(provider);

  const event = parseEventSignatures(fourByteHex);

  if (event === null) return null;

  const parameters =
    event &&
    Object.values(
      web3.eth.abi.decodeParameters(
        event.arguments,
        transaction.input.substring(10)
      )
    )
      .map((value: any, i) => {
        if (i >= event.argumentsOutput.length || !event.argumentsOutput[i])
          return undefined;
        return `${event.argumentsOutput[i]}: ${event.renderArguments[i]?.(
          value
        )}`;
      })
      .filter(Boolean);

  const timeStamp = dayjs.unix(transaction.timestamp).fromNow();

  return (
    <Grid gap={0.15}>
      <Grid gridTemplateColumns="1fr auto">
        <Flex gap={0.25} alignItems="center">
          <Anchor
            target="_blank"
            href={`${HARMONY_EXPLORER_URL}/tx/${transaction.input}`}
          >
            {event?.label || <Spinner />}
          </Anchor>{" "}
          <Responsive
            xs={<div />}
            md={
              <>
                by{" "}
                <a
                  href={`${HARMONY_EXPLORER_URL}/address/${transaction.from}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>{abbreviateETHAddress(fromBech32(transaction.from))}</u>
                </a>
              </>
            }
          />
        </Flex>
        <span style={{ color: "#ddd" }}>{timeStamp}</span>
      </Grid>
      {parameters && (
        <code style={{ fontSize: "9pt" }}>{parameters.join(", ")}</code>
      )}
    </Grid>
  );
};

export function parseEventSignatures(fourByteHex: string[]) {
  // @ts-ignore
  if (!CONTRACT_METHOD_SIGNATURES[fourByteHex]) return null;
  // @ts-ignore
  return CONTRACT_METHOD_SIGNATURES[fourByteHex];
}

export const CONTRACT_METHOD_SIGNATURES = {
  "0xa87e25a": {
    method: "purchaseNFT(address,uint256)",
    label: "Bought NFT",
    arguments: ["address", "uint256"],
    argumentsOutput: ["NFT Address", "Token ID"],
    renderArguments: [
      (nftAddress: string) => {
        return abbreviateETHAddress(nftAddress);
      },
      (tokenID: string) => {
        return Number(tokenID);
      },
    ],
  },
  "0x095ea7b3": {
    method: "approve(address,uint256)",
    label: "Approve KURO",
    arguments: ["address", "uint256"],
    argumentsOutput: [null, "Amount"],
    renderArguments: [
      null,
      (price: string) => `${toSmallN(Number(price), 9).toLocaleString()} KURO`,
    ],
  },
  "0x91a48a1": {
    method: "sellNFT(uint256,uint256)",
    label: "Listed for Sale",
    arguments: ["uint256", "uint256"],
    argumentsOutput: ["Token ID", "Price"],
    renderArguments: [
      (tokenID: string) => {
        return Number(tokenID);
      },
      (price: string) => {
        return `${toSmallN(Number(price), 9).toLocaleString()} KURO`;
      },
    ],
  },
  "0x731133e": {
    method: "mint(address,uint256,uint256,bytes)",
    label: "Minted",
    arguments: ["address", "uint256", "uint256", "bytes"],
    argumentsOutput: [null, "Token ID", null, null],
    renderArguments: [
      null,
      (tokenID: string) => {
        return Number(tokenID);
      },
      null,
      null,
    ],
  },
  "0x1f7fdff": {
    method: "mintBatch(address,uint256[],uint256[],bytes)",
    label: "Batch Minted",
    arguments: ["address", "uint256[]", "uint256[]", "bytes"],
    argumentsOutput: [null, "Token IDs", null, null],
    renderArguments: [
      null,
      (tokenIDs: string[]) => {
        return tokenIDs.map((tokenID) => Number(tokenID)).join(", ");
      },
      null,
      null,
    ],
  },
};
