import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { ArtMuseumPage } from "./types";

type ArtMuseumPageData = {
  artMuseumPageV2: ArtMuseumPage;
};

type ArtMuseumPageVariables = {
  id: string;
};

export const useArtMuseumPage = (
  options?: QueryHookOptions<ArtMuseumPageData, ArtMuseumPageVariables>
) => {
  return useQuery<ArtMuseumPageData, ArtMuseumPageVariables>(
    ART_MUSEUM_PAGE,
    options
  );
};

export const ART_MUSEUM_PAGE = gql`
  query artMuseumPage($id: ID!) {
    artMuseumPageV2(where: { id: $id }) {
      id
      heading
      activeNfts {
        name
        nftAddress
        stakeAddress
      }
      inactiveNfts {
        name
        nftAddress
        stakeAddress
      }
    }
  }
`;
