import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { Footer } from "./types";

type FootersData = {
  footers: Array<Footer>;
};

export const useFooters = (options?: QueryHookOptions<FootersData>) => {
  return useQuery<FootersData>(FOOTERS, options);
};

export const FOOTERS = gql`
  query footers {
    footers {
      copyrightText
      socials {
        name
        link
        internalLink
        icon {
          url
        }
      }
    }
  }
`;
