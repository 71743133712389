import { motion, AnimatePresence } from "framer-motion";
import React from "react";
import { Listing, LoadingImage, NFTCard, NFTTokenURI } from ".";
import MarketplaceContract from "../../compiled-contracts/marketplace/Marketplace.json";
import NFTContract from "../../compiled-contracts/marketplace/NFT.json";
import {
  Button,
  Flex,
  Grid,
  MutationErrors,
  Spinner,
  Vendor,
} from "../../components";
import { MARKETPLACE_CONTRACT_ADDRESS, toSmallN } from "../../utils";
import { useContractMutation, useContractQuery } from "../contracts";
import { Logo, useAlert } from "../shared";

interface MyListingCardProps {
  listing: Listing;
  refetch: () => Promise<void>;
}

const MyListingCard: React.FC<MyListingCardProps> = ({ listing, refetch }) => {
  const alert = useAlert();

  const nftDataQuery = useContractQuery(
    NFTContract,
    listing.nftContract,
    async (contract) => {
      const tokenID = Number(listing.tokenId);

      const url = await contract.methods.uri(tokenID).call();
      const res = await fetch(url);

      if (res.ok) {
        return res.json();
      }
    }
  );

  /* Mutations */

  const unlistNFTMutation = useContractMutation(
    MarketplaceContract,
    MARKETPLACE_CONTRACT_ADDRESS,
    async (contract, ethAddress) => {
      return await contract.methods
        .cancelNFTSale(listing.nftContract, Number(listing.tokenId))
        .send({ from: ethAddress });
    },
    {
      onCompleted: async () => {
        alert.open("Successfully Unlisted");
        await refetch();
      },
    }
  );

  async function handleUnlistNFT() {
    await unlistNFTMutation.mutate({});
  }

  if (!nftDataQuery.data) return null;

  const nft: NFTTokenURI = nftDataQuery.data;

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} layout>
      <NFTCard.Card
        name={<Vendor address={nft.vendor} />}
        image={
          <LoadingImage>
            <AnimatePresence>
              {nft ? (
                <motion.img
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.15 }}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={nft.preview}
                  alt={nft.name}
                />
              ) : (
                <Spinner />
              )}
            </AnimatePresence>
          </LoadingImage>
        }
      >
        <Grid>
          <h2
            style={{
              textAlign: "center",
              marginTop: "-0.5rem",
              fontSize: "16pt",
            }}
          >
            {nft?.name || <Spinner />} #{listing.tokenId}
          </h2>

          <Grid gridTemplateColumns="auto 1fr" alignItems="center" gap={0.5}>
            <Flex alignItems="center" gap={0.25}>
              <div style={{ width: 30, height: 30 }}>
                <Logo />
              </div>
              <p>{toSmallN(Number(listing.price), 9).toLocaleString()} KURO</p>
            </Flex>

            <Button
              loading={unlistNFTMutation.loading}
              disabled={unlistNFTMutation.loading}
              onClick={handleUnlistNFT}
            >
              Unlist
            </Button>

            <MutationErrors>{unlistNFTMutation.error}</MutationErrors>
          </Grid>
        </Grid>
      </NFTCard.Card>
    </motion.div>
  );
};

export { MyListingCard };
